import React, { useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProcMedicoCreate from "./ProcMedicoCreate";
import ProcMedicoTipoCreate from "./ProcMedicoTipoCreate";

const PrimerOffcanvas = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}>
        <i className="bx bx-plus me-1"></i>Crear Procedimiento
      </button>
      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Procedimiento</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ProcMedicoCreate />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};


const SegundoOffcanvas = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}>
        <i className="bx bx-plus me-1"></i>  Crear Tipo Procedimiento
      </button>
      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Tipo de Procedimiento Medicina
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Cuerpo diferente para el segundo Offcanvas */}
          <ProcMedicoTipoCreate />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default function ProcOdontologia({tabs}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  return (
    <section>
      <div className="row">
        <div className="col-12">

          <div className="page-title-box d-sm-flex align-items-center justify-content-between ">
            <div>
              {/* <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}><i className="bx bx-plus me-1"></i> Crear Tipo Procedimiento</button>

              <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}><i className="bx bx-plus me-1"></i> Crear Procedimiento</button> */}

              <PrimerOffcanvas />
              <SegundoOffcanvas />

            </div>
            {/* <div className="spinner-border spin_loading text-primary m-1" role="status" >
              <span className="sr-only">Loading...</span>
            </div> */}
          </div>


          <table id="tb_procedimientos_med" className="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th width="200">Procedimiento</th>
                <th>Tipo</th>
                <th>Valor</th>
                <th>Estado</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td align="center">1</td>
                <td>Medicina</td>
                <td>Procedimientos</td>
                <td>150.00</td>
                <td>Activo</td>
                <td align="center">
                  <div className="btn-group"><button type="button"
                    className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                    <div className="dropdown-menu dropdownmenu-secondary">
                      <a className="dropdown-item" href="javascript:;" onclick="loadInfoProcedimientoMed(1342)">Editar</a>
                      <a className="dropdown-item" href="javascript:;" onclick="eliminarProcedimientoMed(1342)">Eliminar</a>
                      <div className="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">2</td>
                <td>Ortodoncia</td>
                <td>Especialidad</td>
                <td>50.00</td>
                <td>Activo</td>
                <td align="center">
                  <div className="btn-group"><button type="button"
                    className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                    <div className="dropdown-menu dropdownmenu-secondary">
                      <a className="dropdown-item" href="javascript:;" onclick="loadInfoProcedimientoMed(335)">Editar</a>
                      <a className="dropdown-item" href="javascript:;" onclick="eliminarProcedimientoMed(335)">Eliminar</a>
                      <div className="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">3</td>
                <td>Radiografia</td>
                <td>Procedimientos</td>
                <td>20.00</td>
                <td>Activo</td>
                <td align="center">
                  <div className="btn-group"><button type="button"
                    className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                    <div className="dropdown-menu dropdownmenu-secondary">
                      <a className="dropdown-item" href="javascript:;" onclick="loadInfoProcedimientoMed(1345)">Editar</a>
                      <a className="dropdown-item" href="javascript:;" onclick="eliminarProcedimientoMed(1345)">Eliminar</a>
                      <div className="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">4</td>
                <td>Sellante</td>
                <td>Especialidad</td>
                <td>25.00</td>
                <td>Activo</td>
                <td align="center">
                  <div className="btn-group"><button type="button"
                    className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                    <div className="dropdown-menu dropdownmenu-secondary">
                      <a className="dropdown-item" href="javascript:;" onclick="loadInfoProcedimientoMed(1341)">Editar</a>
                      <a className="dropdown-item" href="javascript:;" onclick="eliminarProcedimientoMed(1341)">Eliminar</a>
                      <div className="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>



      {/* <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Procedimiento</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ProcMedicoCreate />
        </Offcanvas.Body>
      </Offcanvas>


      <OffcanvasExa style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Tipo de Procedimiento</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ProcMedicoTipoCreate />
        </Offcanvas.Body>
      </OffcanvasExa> */}

    </section>
  );
}
