import moment from 'moment/moment';
import React, { useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import ContextMenu from './ContextMenu';

function DoctorScheduleTable({ doctoresEspecialidades, fecha, show, setShow, setDoctor, setHora, setCitaPaciente, citasDoctotes, handleChange }) {
  
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    position: { x: 0, y: 0 },
    options: [],
  });

  // Obtener el día actual en formato lunes, martes, etc.
  const dias = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
  const fechaActual = moment(fecha).toDate();
  const diaActual = dias[fechaActual.getDay()];

  function sumarMinutos(hora, minutos) {
    const [horas, minutosActuales] = hora.split(':').map(Number);
    const nuevaHora = new Date();
    nuevaHora.setHours(horas);
    nuevaHora.setMinutes(minutosActuales + minutos);
    return `${String(nuevaHora.getHours()).padStart(2, '0')}:${String(nuevaHora.getMinutes()).padStart(2, '0')}`;
  }

  function calcularIntervalosTiempo(horario_inicio, horario_fin, intervalo_tiempo) {
    const [horaInicio, minutoInicio] = horario_inicio.split(':').map(Number);
    const [horaFin, minutoFin] = horario_fin.split(':').map(Number);
    const intervalos = [];
    let horaActual = horaInicio;
    let minutoActual = minutoInicio;

    while (horaActual < horaFin || (horaActual === horaFin && minutoActual < minutoFin)) {
      intervalos.push(`${String(horaActual).padStart(2, '0')}:${String(minutoActual).padStart(2, '0')}`);
      minutoActual += intervalo_tiempo;
      if (minutoActual >= 60) {
        horaActual += Math.floor(minutoActual / 60);
        minutoActual %= 60;
      }
    }
    return intervalos;
  }

  function isDiaLaboral(doctor) {
    return doctor.doctors_dias_laborables[0][diaActual];
  }

  function isDoctorAvailable(doctor, hora) {
    const [horaInicio, minutoInicio] = doctor.horario_inicio.split(':').map(Number);
    const [horaFin, minutoFin] = doctor.horario_fin.split(':').map(Number);
    const [horaHora, minutoHora] = hora.split(':').map(Number);
  
    // Verifica si la hora está dentro del rango de trabajo del doctor
    if (
      horaHora < horaInicio ||
      (horaHora === horaInicio && minutoHora < minutoInicio) ||
      horaHora > horaFin ||
      (horaHora === horaFin && minutoHora >= minutoFin)
    ) {
      return false;
    }
  
    return true; // Ahora retorna verdadero si está dentro del horario
  }
  
  function isDoctorBusy(doctor, hora) {
    const citasDoctorHora = citasDoctotes.filter((cita) => {
      const horaCita = String(cita.hora_inicio).padStart(5, '0');
      const fechaCita = moment(cita.fecha_inicio).format('YYYY-MM-DD');
      return (
        cita.iddoctor === doctor.iddoctor &&
        horaCita === hora &&
        fechaCita === moment(fecha).format('YYYY-MM-DD')
      );
    });
  
    return citasDoctorHora.length > 0 ? (
    <div >
      {citasDoctorHora[0].pacientes.nombres_completos}
      <br />
     <span className="badge bg-primary rounded-pill">Estado: {citasDoctorHora[0].estado_cita.estado}
     </span>
    </div>) : null;
  }
  
  function isDoctorPaciente(doctor, hora) {
    const citasDoctorHora = citasDoctotes.filter((cita) => {
      const horaCita = String(cita.hora_inicio).padStart(5, '0');
      const fechaCita = moment(cita.fecha_inicio).format('YYYY-MM-DD');
      return (
        cita.iddoctor === doctor.iddoctor &&
        horaCita === hora &&
        fechaCita === moment(fecha).format('YYYY-MM-DD')
      );
    });
    
    if(citasDoctorHora.length > 0){
      return {
        motivo: citasDoctorHora[0].motivo,

        hora_inicio: citasDoctorHora[0].hora_inicio,
        fecha_inicio: citasDoctorHora[0].fecha_inicio,
        hora_fin: citasDoctorHora[0].hora_fin,
        idpaciente: citasDoctorHora[0].pacientes.idpaciente,
        nombre: citasDoctorHora[0].pacientes.nombres_completos,

        id_estado_cita: citasDoctorHora[0].estado_cita.idestado_cita,
        estado: citasDoctorHora[0].estado_cita.estado,
        fecha: citasDoctorHora[0].fecha_inicio,
        iddoctor: citasDoctorHora[0].iddoctor,
        idcita: citasDoctorHora[0].idcita,
        idtipo_cita: citasDoctorHora[0].idtipo_cita,
        celular: "citasDoctorHora[0].celular",
      }
    }else{
      return null;
    }
  }


  const horariosClinica = useMemo(() => {
    if (!doctoresEspecialidades || doctoresEspecialidades.length === 0) return [];
    return calcularIntervalosTiempo(
      doctoresEspecialidades[0].clinica.horario_inicio,
      doctoresEspecialidades[0].clinica.horario_fin,
      doctoresEspecialidades[0].especialidades.clinica_especialidades[0].intervalo_tiempo
    );
  }, [doctoresEspecialidades]);

  const handleCellClick = (doctor, hora, intervalo_tiempo) => {
    if (!isDiaLaboral(doctor)) {
      return Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'El doctor no trabaja en este día: ('+hora+')',
      });
    }
    isDoctorBusy(doctor, hora);
    if (isDoctorAvailable(doctor, hora)) {
      setHora({
        hora_inicio: hora,
        hora_fin: sumarMinutos(hora, intervalo_tiempo),
      });
      let paciente = isDoctorPaciente(doctor, hora);
      if(paciente){
        handleChange({
          label: paciente.nombre,
          value: paciente.idpaciente,
        });
        setCitaPaciente({
          idclinica: doctor.idclinica,
          fecha_inicio: paciente.fecha_inicio,
          iddoctor: paciente.iddoctor,
          hora_inicio: paciente.hora_inicio,
          hora_fin: paciente.hora_fin,
          idpaciente: paciente.idpaciente,
          nombre: paciente.nombre,
          id_estado_cita: paciente.id_estado_cita,
          estado: paciente.estado,
          motivo: paciente.motivo,
          idcita: paciente.idcita,
          idtipo_cita: paciente.idtipo_cita,
          celular: "paciente.celular",
        });

      }else{
        setCitaPaciente({
          idclinica: doctor.idclinica,
          fecha_inicio: fecha,
          iddoctor: doctor.iddoctor,
          hora_inicio: hora,
          hora_fin: sumarMinutos(hora, intervalo_tiempo),
        });
      }

      setDoctor(doctor);
      setShow(!show);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'El doctor no trabaja en este horario o ya tiene una cita programada: ('+hora+')',
      });
    }
  };

  const [holdTimer, setHoldTimer] = useState(null);
  const closeContextMenu = () => {
    setContextMenu({ ...contextMenu, visible: false });
  }

  const handleContextMenu = (e, doctor, hora) => {
    e.preventDefault();
    let paciente = isDoctorPaciente(doctor, hora);
    let doctorsito = doctor;
    if(!paciente) return;
    // Configura un temporizador para abrir el menú contextual tras 500ms
    const timer = setTimeout(() => {
      setContextMenu({
        visible: true,
        position: { 
          x: e.clientX, // Coordenada horizontal del cursor
          y: e.clientY, // Coordenada vertical del cursor
        },
        options: [
          {
            label: `Abrir Consulta `,
            action: () => {
              window.open(`/paciente_historial?idpaciente=${paciente.idpaciente}&idcita=${paciente.idcita}`);
            },
          },
          {
            label: 'Enviar WhatsApp',
            action: () => {
              // 🔹 **Ejemplo de Datos**
              /*const paciente = {
                idpaciente: "593987682180",
                nombre: "Juan Pérez",
                fecha_inicio: "10 de enero de 2025",
                hora_inicio: "10:30 AM"
            };*/

            alert(JSON.stringify(paciente, null, 2));
            //alert(paciente.nombre);
            alert(JSON.stringify(setCitaPaciente, null, 2));

            //alert(paciente.iddoctor);
            
            /*const doctor = {
                nombre: "Dr. Luis García",
                especialidad: "Cardiología"
            };*/

            const clinica = {
                nombre: "Clínica Central",
                direccion: "Av. Principal #123, Quito",
                gmap: "https://goo.gl/maps/ejemplo",
                nombreatendido: "Sra. Ana López"
            };

              // 🔹 **Llamamos a la función**
              enviarWhatsApp(paciente, doctor, clinica);
              //var win = window.open(wpFullLink);
              //window.open(`https://wa.me/${doctor.celular}`)
              //window.open(`https://wa.me/593987682180`)
              
            },
          },
          /*{
            label: 'Enviar Correo',
            action: () => {
              window.open(`mailto:${doctor.correo}`)
            },
          },*/
        ],
      });
    }, 200); // Tiempo en milisegundos para activar el menú contextual

    setHoldTimer(timer);
  };

  function enviarWhatsApp(paciente, doctor, clinica) {
    // Construcción del mensaje con variables dinámicas
    var text = `🏥 *${clinica.nombre}* le informa: %0A
                  ━━━━━━━━━━━━━━━━━━━%0A
                  👤 *Paciente:* ${paciente.nombre}%0A
                  📆 *Fecha:* ${paciente.fecha_inicio}%0A
                  ⏰ *Hora:* ${paciente.hora_inicio}%0A
                  %0A
                  👨‍⚕️ *Doctor:* ${doctor.nombre}%0A
                  🩺 *Especialidad:* ${doctor.especialidades.especialidad}%0A
                  %0A
                  📌 *Dirección:* ${clinica.direccion}%0A
                  🗺️ *Ubicación:* ${clinica.gmap}%0A
                  %0A
                  ⚠️ *Por favor, llegue 15 minutos antes de su cita y lleve su cédula.*%0A
                  ━━━━━━━━━━━━━━━━━━━%0A
                  🙋‍♂️ *Atendido por:* ${clinica.nombreatendido}.`;

    // Construcción de la URL de WhatsApp
    //var url = `https://wa.me/${paciente.idpaciente}?text=${encodeURIComponent(text)}`;
    var url = `whatsapp://send?phone=593987682180&text=${text}`;

    // Abrir WhatsApp con el mensaje prellenado
    window.open(url);
  }

  
  const handleMouseUp = () => {
    // Limpia el temporizador si el botón se suelta antes de los 500ms
    clearTimeout(holdTimer);
  };

  return (
    <div style={{ overflowY: 'auto', maxHeight: '650px' }} 
        onClick={closeContextMenu}
    >
      <table id="calendar" 
        className="table table-bordered table-responsive calendar" 
        style={{ fontSize: '14px' }}>
        <thead>
          <tr>
            <th style={{ position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '1' }}>
              Horario
            </th>
            {doctoresEspecialidades.map((doctor, index) => (
              <th
                key={index}
                style={{
                  position: 'sticky',
                  top: '0',
                  backgroundColor: doctor.calcolor || 'gray',
                  zIndex: '1',
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                {doctor.nombre}
                <br />
                <span className="badge bg-primary rounded-pill">{doctor.horario}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {horariosClinica.map((hora, index) => (
            <tr key={index}>
              <td>{hora}</td>
              {doctoresEspecialidades.map((doctor, idx) => (
                <td
                  key={idx}
                  style={{
                    backgroundColor: isDiaLaboral(doctor)
                      ? (isDiaLaboral(doctor) && isDoctorAvailable(doctor, hora) && !isDoctorBusy(doctor, hora))
                        ? '#F6F9AF'
                      : (isDiaLaboral(doctor) && isDoctorAvailable(doctor, hora) && isDoctorBusy(doctor, hora)) ? '#45e575' : '#F8D7DA'
                      : '#F8D7DA',
                  }}
                  onClick={() => handleCellClick(doctor, hora, doctor.especialidades.clinica_especialidades[0].intervalo_tiempo)}
                  onContextMenu={(e) => handleContextMenu(e, doctor, hora)}
                  onMouseUp={handleMouseUp}
                >
                  {(isDiaLaboral(doctor) && isDoctorAvailable(doctor, hora) && !isDoctorBusy(doctor, hora)) ? 'Disponible': ''}
                  <br />
                  {isDoctorBusy(doctor, hora)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* Componente ContextMenu */}
      <ContextMenu
        visible={contextMenu.visible}
        position={contextMenu.position}
        options={contextMenu.options}
        onClose={closeContextMenu}
      />
    </div>
  );
}

export default DoctorScheduleTable;
