import { BmHttp } from "../utils/Global";

export const guardarExamenLaboratorioService = async (examen) => {
  try {
    const { data } = await BmHttp().post("examen_registro_cita", examen);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const guardarExamenImagenesService = async (examen) => {
  try {
    const { data } = await BmHttp().post("examen_registro_cita_imagen_orden", examen);
    return data;
  } catch (error) {
    console.error(error);
  }
};


export const ListCategoriaExamenProcedimientos = async (value) => {
  try {
    if(value){
      const { data } = await BmHttp().post(`get_procedimientos_all`,{
        value
      });
      return data;
    }
    const { data } = await BmHttp().get(`get_procedimientos_all`);
    return data;
  } catch (error) {
    console.log("error", error);
  }
};

export const ListarProcedimientoConPrecios  = async () => {
  try {
    const { data } = await BmHttp().get(`get_procedimientos_all_precios`);
    return data;
  } catch (error) {
    console.log("error", error);
  }
}

export const EliminarProcedimientoPrecio = async (idprocedimiento_company_precios) => {
  try {
    const { data } = await BmHttp().post(`delete_procedimiento_precio`,
      {idprocedimiento_company_precios}
    );
    return data;
  } catch (error) {
    console.log("error", error);
  }
}

/**
 * Crear procedimiento precio
 * @Object {
 *  idexamen_tipo: number,
  * precio: number,
  * idcompany: number
  *  descuento: number
 * }
 * 
 */
export const CrearProcedimientoPrecio = async (procedimiento) => {
  try {
    const { data } = await BmHttp().post(`create_procedimiento_precio`, procedimiento);
    return data;
  } catch (error) {
    console.log("error", error);
  }
}

export const ActualizarProcedimientoPrecio = async (procedimiento) => {
  try {
    const { data } = await BmHttp().post(`update_procedimiento_precio`, procedimiento);
    return data;
  } catch (error) {
    console.log("error", error);
  }
}