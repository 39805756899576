import React from 'react';
import Flatpickr from "react-flatpickr";
export default function FacturaListar() {


  return (
    <>
      <div className="page-content">

        <div className="container-fluid">

          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Listado de Facturas</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="javascript: void(0);">Facturas</a></li>
                    <li className="breadcrumb-item active">Listado</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">

              <div className="row">

                <div className="form-group col-md-2" >
                  <label for="inputEmail4">Clinicas</label>
                  <select className="form-select " id="fc_idclinica" name="fc_idclinica" >
                    <option value="0">Juan</option>
                    <option value="1">Betty</option>
                    <option value="2">David</option>
                  </select>
                </div>

                <div className="form-group col-md-3" >
                  <label for="inputEmail4">Fecha</label>
                  {/* <input id="fechas-facturas" name="fechas-facturas" style={{maxWidth:'100%'}} value="<?= date('Y-m-d') ?> to <?= date('Y-m-d') ?>"
                    className="form-control" type="text" /> */}
                     <Flatpickr style={{maxWidth:'100%'}} className='form-control' options={{ minDate: "2017-01-01" }} />
                </div>

                <div className="form-group col-md-4 " >
                  <label for="inputEmail4">Paciente</label>

                  <div className="row">
                    <div className="col-10">
                      <select className="form-select" id="fc-paciente" name="fc-paciente" onchange="showEmptyBoxPaciente()"></select>
                    </div>
                    <div className="col-2">
                      <button id="btn_emptyPaciente" type="button" onclick="emptyBoxPaciente()" style={{display:'none'}}
                        className="btn btn-soft-light waves-effect waves-light" ><i className="fas fa-trash"></i></button>
                    </div>
                  </div>
                </div>



                <div className="form-group col-md-3" >
                  <label for="inputEmail4">Opciones</label><br/>
                    <button type="button" className="btn btn-soft-success waves-effect waves-light btnSaveInfo" onclick="consultarFacturas()">
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 spin_loading" style={{display: 'none'}} ></i>
                      Consultar</button>
                    <a href="facturasCrear" className="btn btn-soft-secondary waves-effect btn-label waves-light">
                      <i className="bx bx-plus label-icon"></i> Factura</a>
                </div>

              </div>
            </div>
          </div>


          <div className="mt-4 mb-4">
            <div className="card">
              <div className="card-body">
                <table id="tb_facturas_lts" className="table align-middle datatable dt-responsive table-check nowrap table-hover"
                  style={{borderCollapse: 'collapse', borderSpacing: '0 8px', width: '100%', fontSize:'14px'}}>
                  <thead>
                    <tr className="table-light">
                      <th>#</th>
                      <th>Fecha</th>
                      <th>Clinica</th>
                      <th>Paciente</th>
                      <th>Cliente</th>
                      <th className='text-center'>Doc</th>
                      <th className='text-center'>Num. Doc</th>
                      <th className='text-center'>Subtotal</th>
                      <th className='text-center'>Impuesto</th>
                      <th className='text-center'>Total</th>
                      <th className='text-center'>Estado</th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>

                  <tbody>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

        </div>


        <div className="modal fade" id="modalFacturaDetalle" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalScrollableTitle">Detalle Factura</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">

                @include('facturas.factura-detalle')

              </div>
              <div className="modal-footer">
                <a href="javascript:window.print()" className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i></a>
                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>



      </div >


    </>
  )
}
