import Swal from "sweetalert2";

export const posologiaFailureToast = () => {
  Swal.fire({
    title: 'Error en el formulario',
    text: 'Debe tener un valor en el campo posologia',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}

export const cantidadFailureToast = () => {
  Swal.fire({
    title: 'Error en el formulario',
    text: 'Debe tener un valor en el campo cantidad',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}

export const dosisFailureToast = () => {
  Swal.fire({
    title: 'Error en el formulario',
    text: 'Debe tener un valor en el campo dosis',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}

export const registerRecetaSuccessToast = () => {
  Swal.fire({
    title: 'Registro exitoso',
    text: 'Los datos han sido guardados exitosamente',
    icon: 'success',
    confirmButtonText: 'Aceptar'
  });
}

export const registerRecetaFailureToast = () => {
  Swal.fire({
    title: 'Registro fallido',
    text: 'Ha ocurrido un error al guardar los datos, intente más tarde',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}