import React, { useState } from "react";
import { useDispatch } from "react-redux";
import InfoHistorial from "../Components/PerfilPaciente/Antecedente/InfoHistorial";
import Cuenta from "../Components/PerfilPaciente/Cuenta";
import Factura from "../Components/PerfilPaciente/Factura";
import Inicio from "../Components/PerfilPaciente/Inicio";
import InfoMedicina from "../Components/PerfilPaciente/Medicina/InfoMedicina";
import InfoOdo from "../Components/PerfilPaciente/Odontologia/InfoOdo";

export default function HistorialClinico() {
  const [ping, setPing] = useState(false);
  const dispatch = useDispatch();

  function getLstConsultas(e) {
    dispatch({
      type: "SET_HISTORIAL",
      payload: !ping,
    });
    console.log("getLstConsultas");
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Historial Clínico</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">Historial Clínico</li>
                    <li className="breadcrumb-item active">Listado</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <ul
                      className="nav nav-tabs-custom card-header-tabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link px-3 active"
                          data-bs-toggle="tab"
                          href="#home-profile"
                          role="tab"
                          onclick="loadResumen()"
                        >
                          INICIO
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link px-3"
                          data-bs-toggle="tab"
                          href="#anamnesis"
                          role="tab"
                        >
                          ANTECEDENTES
                        </a>
                      </li>
                      {/* <li className="nav-item">
                            <a className="nav-link px-3" data-bs-toggle="tab" href="#odontologia" role="tab" onclick="getProcePacienteOdon()">ODONTOLOGIA</a>
                        </li> */}
                      <li className="nav-item">
                        <a
                          className="nav-link px-3"
                          data-bs-toggle="tab"
                          href="#post"
                          role="tab"
                          id="medicina-tab"
                          onClick={getLstConsultas}
                        >
                          MEDICINA
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link px-3"
                          data-bs-toggle="tab"
                          href="#estado-cuenta"
                          role="tab"
                          onclick="ltsEstadoCuenta()"
                        >
                          ESTADO CUENTA
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link px-3"
                          data-bs-toggle="tab"
                          href="#facturas-paciente"
                          role="tab"
                          onclick="consultarFacturas()"
                        >
                          FACTURAS
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link px-3"
                          data-bs-toggle="tab"
                          href="#historial_paciente"
                          role="tab"
                          onclick="loadInfoHistorial()"
                        >
                          HISTORIAL
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    id="home-profile"
                    role="tabpanel"
                  >
                    <Inicio />
                  </div>

                  <div className="tab-pane" id="anamnesis" role="tabpanel">
                    <div className="card">
                      {/* <div className="card-header">
                            <h5 className="card-title mb-0">Odontologia</h5>
                        </div> */}
                      <div className="card-body">
                        <InfoHistorial />
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane" id="odontologia" role="tabpanel">
                    <InfoOdo />
                  </div>

                  <div className="tab-pane" id="historial_paciente" role="tabpanel">
                    <div className="card">
                      <div className="card-body">
                        @include("pacientes-profile.historial.historial")
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane" id="estado-cuenta" role="tabpanel">
                    <Cuenta />
                  </div>

                  <div className="tab-pane" id="facturas-paciente" role="tabpanel">
                    <Factura />
                  </div>

                  <div className="tab-pane" id="post" role="tabpanel">
                    <InfoMedicina />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
