/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { CreacionCompany } from "../../../Service/Service.compania";
import { SubirMedia } from "../../../Service/ServiceExternal";
import { GetTokenDecoded } from "../../../utils/storeUsuario";
import Alert from "../../Alert/Alert";
import Separador from "../../Separador/Separador";

export default function CompanyCreate({setPing, setShow, editarCompany}) {


    const refdiv = useRef(null);
    const [send, setSend] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [createCompany, setCreateCompany] = useState({
        idcompany: 0,
        company: '',
        direccion: '',
        ciudad: '',
        email: '',
        ruc: '',
        pais: '',
        provincia: '',
        canton: '',
        cant_clinicas: 1,
        cant_doctores: 1,
        cant_administrativo: 1,
        user:{
            full_name: '',
            user_name: '',
            user_email: '',
            user_level: 'admin',
            pwd: '',
            banned: 0,
            foto_user: '',
            idclinica: 0,
            idcompany: 0,
            sidebar: false,
            tips: false,
            tour: false,
        }
    });

    useEffect(()=>{
      if(editarCompany && editarCompany.idcompany !== 0){
        setCreateCompany({
          ...editarCompany
        })
      }else{
        setCreateCompany({
          idcompany: 0,
          company: '',
          direccion: '',
          ciudad: '',
          email: '',
          ruc: '',
          pais: '',
          provincia: '',
          canton: '',
          cant_clinicas: 1,
          cant_doctores: 1,
          cant_administrativo: 1,
          user:{
              full_name: '',
              user_name: '',
              user_email: '',
              user_level: 'admin',
              pwd: '',
              banned: 0,
              foto_user: '',
              idclinica: 0,
              idcompany: 0,
              sidebar: false,
              tips: false,
              tour: false,
          }
        })
      }
    },[])


    const handleInputChange = (e) => {
        // si es un campo del usuario
        if(e.target.name.includes('user_') || e.target.name === 'pwd' || e.target.name === 'full_name'){
            setCreateCompany({
                ...createCompany,
                user: {
                    ...createCompany.user,
                    [e.target.name]: e.target.value
                }
            });
            return;
        }

        setCreateCompany({
            ...createCompany,
            [e.target.name]: e.target.value
        });
    }

    // avatar
    const CargarAvatar = async (file) => {
        const url = await SubirMedia(file, null, null, GetTokenDecoded().idclinica, 'avatar');
        if (url !== null) {
          setCreateCompany({
            ...createCompany,
            user: {
              ...createCompany.user,
              foto_user: url
            }
          });
          return url;
        } else {
          return null;
        }
    };

    const CrearCompania = async (e) => {
        e.preventDefault();
        setSend(true)
        const response = await CreacionCompany(createCompany);
        if(response){
          setAlertType('success')
        }else{
          setAlertType('error')
        }
        setSend(false)
        refdiv.current.scrollIntoView({behavior: 'smooth'})
        setTimeout(()=>{
          setAlertType('')
          setPing(true)
          setShow(false)
        },2500)
    }


  return (
    <section>
      <Alert
          tipo_alert={alertType}
          type={alertType}
      />
      <div className="row">
      <div
        ref={refdiv}  
      />
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form id="form-compania-create">
                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombre Compania *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="company" id="nombre-compania" required
                        onChange={handleInputChange}
                        value={createCompany.company}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Direccion *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="direccion" id="direccion-compania" onChange={handleInputChange} 
                      value={createCompany.direccion}
                    />

                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Ciudad *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="ciudad" id="ciudad-compania" onChange={handleInputChange} 
                      value={createCompany.ciudad}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Email *</label>
                  <div className="col-sm-9">
                    <input type="email" className="form-control" name="email" id="email-compania" onChange={handleInputChange} 
                      value={createCompany.email}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Ruc *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="ruc" id="ruc-compania" onChange={handleInputChange} 
                      value={createCompany.ruc}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Pais *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="pais" id="pais-compania" onChange={handleInputChange} 
                      value={createCompany.pais}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Provincia *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="provincia" id="provincia" onChange={handleInputChange} required
                      value={createCompany.provincia}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Canton *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="canton" id="canton-compania" required onChange={handleInputChange}
                      value={createCompany.canton}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Cantidad de clinicas *</label>
                  <div className="col-sm-9">
                    <input type="number" className="form-control" name="cant_clinicas" id="cantidad-clinicas" required onChange={handleInputChange}
                      value={createCompany.cant_clinicas}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Cantidad de doctores *</label>
                  <div className="col-sm-9">
                    <input type="number" className="form-control" name="cant_doctores" id="cant_doctores" required onChange={handleInputChange} 
                      value={createCompany.cant_doctores}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Cantidad de administradores *</label>
                  <div className="col-sm-9">
                    <input type="number" className="form-control" name="cant_administrativo" id="cantidad-administradores" required onChange={handleInputChange} 
                      value={createCompany.cant_administrativo}
                    />
                  </div>
                </div>

                {
                  createCompany.idcompany === 0  && (
                    <>
                    <Separador text="Informacion usuario Administrador" />
                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombres completos *</label>
                      <div className="col-sm-9">
                        <input type="text" className="form-control" name="full_name" id="full_name" required onChange={handleInputChange} />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombres de usuario</label>
                      <div className="col-sm-9">
                        <input type="text" className="form-control" name="user_name" id="user_name" onChange={handleInputChange} />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Usuario Email *</label>
                      <div className="col-sm-9">
                        <input type="text" className="form-control" name="user_email" id="user_email" required onChange={handleInputChange} />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Clave *</label>
                      <div className="col-sm-9">
                        <input type="text" className="form-control" name="pwd" id="pwd" required onChange={handleInputChange}/>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">avatar</label>
                      <div className="col-sm-9">

                        <div className="input-group">
                          <span className="input-group-btn">
                            <span className="btn btn-default btn-file">
                              Browse… <input type="file" id="imgInp" name="imgInp" accept="image/gif, image/jpeg, image/png" onChange={(e) => CargarAvatar(e.target.files[0])} />
                            </span>
                          </span>
                        </div>
                        <br/>
                          <input type="hidden" id="hlogo" name="hlogo" />
                            <div align="left">
                                {
                                    createCompany.user.foto_user === '' ? <img src="https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg" id="img-upload" className="img-thumbnail" data-holder-rendered="true" style={{maxWidth: '250px'}} /> : 
                                    <img  src={createCompany.user.foto_user} id='img-upload' className="img-thumbnail" data-holder-rendered="true" style={{maxWidth: '250px'}} />
                                }
                            </div>
                          </div>
                      </div>
                    </>
                  )

                }

                  <div className="text-center">
                    <button onclick="openModalClinica()" className="btn btn-danger m-1">Cancelar</button>
                    <button type="submit" className="btn btn-primary btnSaveInfo" onClick={(e)=>CrearCompania(e)}>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" 
                        style={{
                          display: send ? 'inline-block' : 'none' 
                        }}></i>
                      Guardar
                    </button>
                  </div>

              </form>


            </div>
          </div>
        </div>
      </div>


    </section>
  );
}
