import { BmHttp } from "../utils/Global";

export const Controllerenfermendad = async (enfermedad) => {
  try {
    const { data } = await BmHttp().post("consulta_enfermedad", enfermedad);
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const ControllerReceta = async (receta) => {
  try {
    const { data } = await BmHttp().post("consulta_receta", receta);
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const ControllerEliminaReceta = async (idreceta) => {
  try {
    const { data } = await BmHttp().delete("consulta_receta/"+ idreceta );
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export const ControllerDiagnosticos = async () => {
  try {
    const { data } = await BmHttp().get("consulta_diagnosticos");
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const ControllerDiagnosticoSearch = async (search) => {
  try {
    const { data } = await BmHttp().post("consulta_diagnosticos", { search });
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
