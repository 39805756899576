import React, { useState } from 'react';
import Administrativo from '../Components/Configuracion/Administrativo';
import Clinicas from '../Components/Configuracion/Clinica';
import Company from '../Components/Configuracion/Company/Company';
import Decuentos from '../Components/Configuracion/Descuentos/Descuentos';
import Doctor from '../Components/Configuracion/Doctor';
import Especialidad from '../Components/Configuracion/Especialidad/Especialidad';
import ProcedimientoPrecio from '../Components/Configuracion/ProcedimientoPrecio/ProcedimientoPrecio';
import ProcMedico from '../Components/Configuracion/ProcMedico';
import ProcOdontologia from '../Components/Configuracion/ProcOdontologia';
import Seguros from '../Components/Configuracion/Seguros/Seguros';
import { GetTokenDecoded } from '../utils/storeUsuario';

export default function Configuracion() {

  const [tabs, setTabs] = useState({
    company: false,
    clinicas: false,
    doctores: false,
    especialidades: false,
    asesores: false,
    prodOdonto: false,
    prodMed: false
  })

  const handleTabs = (tab)=>{
    // todo los pasamos a false
    Object.keys(tabs).forEach(key => {
      tabs[key] = false
    })
    // activamos el tab que se le dio click
    setTabs({
      ...tabs,
      [tab]: true
    })
  }

  console.log(  
    GetTokenDecoded()
  )

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {/* <!-- start page title --> */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18"><span id="titleConf"></span>

                </h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="#;">Configuración</a></li>
                    <li className="breadcrumb-item active" id="liTitleConf">Clínicas</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
        </div>



        <div className="row">
          <div className="col-12">
            {/* <!-- Left sidebar --> */}
            <div className="email-leftbar card">

              <div className="nav flex-column nav-pills mail-list mt-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                {
                  GetTokenDecoded().user_level === "superadmin" ? (
                    <a className="nav-link mb-2 active" id="t-config-company-tab" data-bs-toggle="pill" href="#t-config-company" role="tab" aria-controls="t-config-company" aria-selected="true" onClick={(e)=>handleTabs('company')}>Company</a>
                  ):null
                }
                <a className="nav-link mb-2" id="t-config-clinicas-tab" data-bs-toggle="pill" href="#t-config-clinicas" role="tab" aria-controls="t-config-clinicas" aria-selected={GetTokenDecoded().user_level === "superadmin" ? "false" : "true"} onClick={(e)=>handleTabs('clinicas')}>Clínicas</a>
                <a className="nav-link mb-2" id="t-config-doctores-tab" data-bs-toggle="pill" href="#t-config-doctores" role="tab" aria-controls="t-config-doctores" aria-selected="false" onClick={(e)=>handleTabs('doctores')}>Doctores</a>
                <a className="nav-link mb-2" id="t-config-especialidades-tab" data-bs-toggle="pill" href="#t-config-especialidades" role="tab" aria-controls="t-config-especialidades" aria-selected="false" onClick={(e)=>handleTabs('especialidades')}>Activar Especialidades</a>
                <a className="nav-link mb-2" id="t-config-asesores-tab" data-bs-toggle="pill" href="#t-config-asesores" role="tab" aria-controls="t-config-asesores" aria-selected="false" onClick={(e)=>handleTabs('asesores')} >Administrativo</a>
                <a className="nav-link mb-2" id="t-config-seguros-tab" data-bs-toggle="pill" href="#t-config-seguros" role="tab" aria-controls="t-config-seguros" aria-selected="false" onClick={(e)=>handleTabs('seguros')} >Seguros</a>
                <a className="nav-link mb-2" id="t-config-descuento-tab" data-bs-toggle="pill" href="#t-config-descuento" role="tab" aria-controls="t-config-descuento" aria-selected="false" onClick={(e)=>handleTabs('descuento')} >Descuento</a>
                <a className="nav-link mb-2" id="t-config-procedimiento-precio-tab" 
                data-bs-toggle="pill" href="#t-config-procedimiento-precio" role="tab" 
                aria-controls="t-config-procedimiento-precio" aria-selected="false" onClick={(e)=>handleTabs('procedimientoPrecio')} >Procedimientos <br /> Precio</a>
                {/* <a className="nav-link" id="t-config-prod-odonto-tab" data-bs-toggle="pill" href="#t-config-prod-odonto" role="tab" aria-controls="t-config-prod-odonto" aria-selected="false" onClick={(e)=>handleTabs('prodOdonto')}>Procedimientos <br /> Odontologia</a>
                <a className="nav-link" id="t-config-prod-med-tab" data-bs-toggle="pill" href="#t-config-prod-med" role="tab" aria-controls="t-config-prod-med" aria-selected="false" onClick={(e)=>handleTabs('prodMed')} >Procedimientos <br /> Medicos</a> */}

              </div>

            </div>

            {/* <!-- Right Sidebar --> */}
            <div className="email-rightbar mb-3">
              <div className="card">

                <div className="tab-content text-muted mt-4 mt-md-0 p-3" id="v-pills-tabContent">

                {
                  GetTokenDecoded().user_level === "superadmin" ? (
                    <div className={tabs.company ? "tab-pane fade show active" : "tab-pane fade"} id="t-config-company" role="tabpanel" aria-labelledby="t-config-company-tab">
                      <Company 
                        tabs={tabs}
                      />
                    </div>
                  ):null
                }

                  <div className={GetTokenDecoded().user_level === "superadmin"? "tab-pane fade" : "tab-pane fade show active"} id="t-config-clinicas" role="tabpanel" aria-labelledby="t-config-clinicas-tab">
                    <Clinicas 
                      tabs={tabs} 
                    />
                  </div>

                  <div className="tab-pane fade" id="t-config-doctores" role="tabpanel" aria-labelledby="t-config-doctores-tab">
                    <Doctor 
                     tabs={tabs}
                    />
                  </div>

                  <div className="tab-pane fade" id="t-config-especialidades" role="tabpanel" aria-labelledby="t-config-especialidades-tab">
                    <Especialidad 
                      tabs={tabs}
                    />
                  </div>

                  <div className="tab-pane fade" id="t-config-asesores" role="tabpanel" aria-labelledby="t-config-asesores-tab">
                    <Administrativo 
                      tabs={tabs}
                    />
                  </div>
                  <div className="tab-pane fade" id="t-config-seguros" role="tabpanel" aria-labelledby="t-config-seguros-tab">
                    <Seguros
                      tabs={tabs}
                    />
                  </div>

                  <div className="tab-pane fade" id="t-config-descuento" role="tabpanel" aria-labelledby="t-config-descuento-tab">
                    <Decuentos
                      tabs={tabs}
                    />
                  </div>

                  <div className="tab-pane fade" id="t-config-procedimiento-precio" role="tabpanel" aria-labelledby="t-config-procedimiento-precio-tab">
                    <ProcedimientoPrecio
                      tabs={tabs}
                    />
                  </div>

                  <div className="tab-pane fade" id="t-config-prod-odonto" role="tabpanel" aria-labelledby="t-config-prod-odonto-tab">
                    <ProcOdontologia
                      tabs={tabs}
                    />
                  </div>

                  <div className="tab-pane fade" id="t-config-prod-med" role="tabpanel" aria-labelledby="t-config-prod-med-tab">
                    <ProcMedico 
                      tabs={tabs}
                    />
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>

      </div >

    </>
  )
}
