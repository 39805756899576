import { BmHttp } from "../utils/Global"

export const ControllerTipoAntecedente = async () => {
    try {
        const { data } = await BmHttp().get('tipo_antecedente')
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const ControllerCrearTipoAntecedente = async (categoria_antecendente) => {
    try {
        const {data} = await BmHttp().post('tipo_antecedente', {categoria_antecendente})
        return data
    } catch (error) {
        console.error(error)
        return null
    }
}

export const ControllerActualizaTipoAntecedente = async (id_categoria_antecedente, categoria_antecendente) => {
    try {
        const {data} = await BmHttp().put(`tipo_antecedente`, {id_categoria_antecedente, categoria_antecendente})
        return data
    } catch (error) {
        console.error(error)
        return null
    }
}

export const ControllerListaDescripcionAntecedente = async (id_categoria_antecedente) => {
    try {
        const {data} = await BmHttp().get(`descripcion_antecedente?id_categoria_antecedente=${id_categoria_antecedente}`)
        return data
    } catch (error) {
        console.error(error)
    }
}

export const ControllerCrearDescripcionAntecedente = async (id_categoria_antecedente, descripcion) => {
    try {
        const {data} = await BmHttp().post(`descripcion_antecedente`, {id_categoria_antecedente, descripcion})
        return data
    } catch (error) {
        console.error(error)
    }
}

export const ControllerAsignacionAntecendentePaciente = async (id_antecedente, observacion, idpaciente, tiene, cp, sp) => {
    try {
        const {data} = await BmHttp().post(`asigancion_antecedente`, {id_antecedente, observacion, idpaciente, tiene, cp, sp})
        return data
    } catch (error) {
        console.error(error)
    }
}

export const ControllerListarAntecedentepaciente = async (idpaciente, id_categoria_antecedente) => {
    try {
        const {data} = await BmHttp().post(`asigancion_antecedente_lista`, {idpaciente:Number(idpaciente), id_categoria_antecedente})
        return data
    } catch (error) {
        console.error(error)
    }
}