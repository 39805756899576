import React, { useEffect, useState } from 'react'
import { ControllerAsignacionAntecendentePaciente, ControllerListarAntecedentepaciente, ControllerTipoAntecedente } from '../../../Service/Service.antecedente'
import Familiar from './Familiar'
import Fisico from './Fisicos'
import Organos from './Organos'
import Personal from './Personal'

export default function InfoHistorial() {
    const [tipoAntecedente, setTipoAntecedente] = useState([])
    const [descripcion, setDescripcion] = useState([])
    const [tipo, setTipo] = useState(null)

    useEffect(() => {
        (async()=>{
            const lis = await ControllerTipoAntecedente()
            if(lis && lis.success){
                setTipoAntecedente(lis.data)
                const urlParams = new URLSearchParams(window.location.search)
                let id_paciente = urlParams.get('idpaciente')
                if(!id_paciente) return console.log('No se encontro el id del paciente')
                const pas = await ControllerListarAntecedentepaciente(id_paciente, lis.data[0].id_categoria_antecedente)
                console.log("Pass: ", pas.data)
                if(pas && pas.success){
                    setDescripcion([])
                    setDescripcion(pas.data)
                }
            }
        })()
    }, [])

    const handleClickTipo = async(tipo) => {
        setTipo(tipo)
        const urlParams = new URLSearchParams(window.location.search)
        let id_paciente = urlParams.get('idpaciente')
        if(!id_paciente) return console.log('No se encontro el id del paciente')
        const pas = await ControllerListarAntecedentepaciente(id_paciente, tipo)
        if(pas && pas.success){
            setDescripcion([])
            setDescripcion(pas.data)
        }
    }

    const asignarAntecedente = async(id_antecedente, observacion, idpaciente, tiene, cp, sp, id_categoria_antecedente) => {
        console.log(id_antecedente, observacion, idpaciente, tiene, cp, sp)
        const asig = await ControllerAsignacionAntecendentePaciente(id_antecedente, observacion, idpaciente, tiene, cp, sp)
        if(asig && asig.success){
            const pas = await ControllerListarAntecedentepaciente(idpaciente, id_categoria_antecedente)
            if(pas && pas.success){
                setDescripcion([])
                setDescripcion(pas.data)
            }
        }
    }

  return (
    <>
        <div className="row">
            <div className="col-12">
                <div className="email-leftbar card" style={{width: "163px"}}>

                    <div className="nav flex-column nav-pills mail-list mt-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {
                            tipoAntecedente.map((tipo, index) => (
                                <a key={index} className="nav-link mb-2"
                                    id={`t-ananm-${String(tipo.categoria_antecendente).toLowerCase().replace(" ","-")}-tab`} 
                                    data-bs-toggle="pill"
                                    href={`#t-ananm-${String(tipo.categoria_antecendente).toLowerCase().replace(" ","-")}`} 
                                    role="tab"
                                    aria-controls={`t-ananm-${String(tipo.categoria_antecendente).toLowerCase().replace(" ","-")}`}
                                    aria-selected="false"
                                    onClick={(e)=>handleClickTipo(tipo.id_categoria_antecedente)}>{tipo.categoria_antecendente}
                                </a>
                            ))
                        }
                    </div>

                </div>

                <div className="email-rightbar mb-3" style={{marginLeft: "177px"}}>
                    <div className="card">

                        <div className="tab-content text-muted mt-4 mt-md-0 p-3" id="v-pills-tabContent">

                            <div className="tab-pane fade show active" id="t-ananm-personales" role="tabpanel" aria-labelledby="t-ananm-personales-tab">
                                <Personal
                                    descripcion={descripcion}
                                    setDescripcion={setDescripcion}
                                    asignarAntecedente={asignarAntecedente}
                                />
                            </div>

                            <div className="tab-pane fade" id="t-ananm-familiares" role="tabpanel" aria-labelledby="t-ananm-familiares-tab">

                                <Familiar
                                    descripcion={descripcion}
                                    setDescripcion={setDescripcion}
                                    asignarAntecedente={asignarAntecedente}
                                />

                            </div>
                            <div className="tab-pane fade" id="t-ananm-organos" role="tabpanel" aria-labelledby="t-ananm-organos-tab">

                                <Organos
                                    descripcion={descripcion}
                                    setDescripcion={setDescripcion}
                                    asignarAntecedente={asignarAntecedente}
                                />

                            </div>
                            <div className="tab-pane fade" id="t-ananm-revison-fisica" role="tabpanel" aria-labelledby="t-ananm-revison-fisica-tab">

                                <Fisico
                                    descripcion={descripcion}
                                    setDescripcion={setDescripcion}
                                    asignarAntecedente={asignarAntecedente}
                                />

                            </div>

                        </div>

                    </div>
                </div>

                </div>
        </div>
    </>
  )
}
