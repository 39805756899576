import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ControllerDiagnosticoSearch } from "../../../Service/Service.consulta";
import { GuardarDiagnosticoPaciente } from "../../../Service/Service.diagnostico";
import { getParams } from "../../../utils/Params";
import { GetTokenDecoded } from "../../../utils/storeUsuario";
import SelectSearchble from "../../SelectSeachble/SelectSearchble";
import { registerPacienteFailureToast, registerPacienteSuccessToast } from "./ModalPacienteToast";
import { validateFormPaciente } from "./ModalPacienteValidation";

function ModalPaciente(props) {
  const { show, onHide, cita } = props;

  const doctor = cita.doctores;
  const userLocal = GetTokenDecoded();
  const params = getParams();

  const tipoDiagnosticosArray = [
    {
      label: "Definitivos",
      value: "DEFINITIVOS",
    },
    {
      label: "Presuntivos",
      value: "PRESUNTIVOS",
    },
  ];

  const [diagnosticos, setDiagnosticos] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [tipoDiagnostico, setTipoDiagnostico] = useState(null);

  const [form, setForm] = useState({
    iddiagnostico_cita_paciente: 0,
    idcita: 0,
    idpaciente: 0,
    iddiagnostico: 0,
    iddoctor: 0,
    idclinica: 0,
    comentario: "",
    typo_diagnostico: "",
  });

  const loadDiagnosticos = async () => {
    const result = await ControllerDiagnosticoSearch("");

    if (result && result.success) {
      result.data.map((d) => {
        d.value = d.id;
        d.label = d.codigo + "  " + d.nombre;
      });

      setDiagnosticos(result.data);
    }
  };

  const changeHandler = (selectedOption) => {
    setSelectedOption(selectedOption);
    setForm({
      ...form,
      iddiagnostico: selectedOption.value,
      idcita: cita.idcita,
      iddoctor: doctor.iddoctor,
    });
  };

  const changeTipoDiangnosticoHandler = (tipoDiagnostico) => {
    setTipoDiagnostico(tipoDiagnostico);
    setForm({
      ...form,
      typo_diagnostico: tipoDiagnostico.value,
    });
  };

  const clearForm = () => {
    setForm({
      ...form,
      comentario: "",
      typo_diagnostico: "",
    });

    setSelectedOption(0);
    setTipoDiagnostico("");
  };

  const closeModal = () => {
    clearForm();
    onHide();
  };

  const sendForm = async () => {
    if (!validateFormPaciente(form)) {
      return;
    }

    const { data } = await GuardarDiagnosticoPaciente(form);
    
    if (!data) {
      registerPacienteFailureToast();
      return;
    }

    registerPacienteSuccessToast();
    closeModal();
  };

  useEffect(() => {
    (async () => {
      await loadDiagnosticos();

      setForm({
        ...form,
        idpaciente: Number.parseInt(params.idpaciente),
        idclinica: userLocal.idclinica,
      });
    })();
  }, []);

  return (
    <>
      <Modal show={show} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Diagnostico del paciente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row gap-15">
            <div className="col-12">
              <div className="form-group">
                <label for="inputEmail4" className="text-uppercase">
                  Diagnosticos
                </label>
                <SelectSearchble
                  listCompany={diagnosticos}
                  handleChange={changeHandler}
                  selectedOption={selectedOption}
                  placeholder="Seleccione Diagnostico"
                  isMulti={false}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="text-uppercase" for="tipoDiagnostico">
                  Tipo de diagnóstico
                </label>
                <Select
                  isMulti={false}
                  id="tipoDiagnostico"
                  options={tipoDiagnosticosArray}
                  placeholder="Seleccione un tipo"
                  value={tipoDiagnostico}
                  onChange={changeTipoDiangnosticoHandler}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label for="indicaciones" className="text-uppercase">
                  Comentarios
                </label>
                <textarea
                  className="form-control"
                  rows="2"
                  id="indicaciones"
                  value={form.comentario}
                  onChange={(e) =>
                    setForm({ ...form, comentario: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="mx-auto"
            type="button"
            onClick={() => sendForm()}
          >
            <i className="bx bxs-save me-1 mt-1"></i>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalPaciente;
