// Fcaturacion info Store

import Swal from "sweetalert2";
import { facturaCliente } from "../utils/Global";

export const SetProcedimientoFactura = (data) => {
    try {
        let pro = localStorage.getItem(facturaCliente);
        console.log(pro);
        if(pro) {
            pro = JSON.parse(pro);
            var existe = pro.find(p => p.idprocedimiento_cita_paciente === data.idprocedimiento_cita_paciente);
            if(existe){
                return false;
            }
            pro = [...pro, data];
            localStorage.setItem(facturaCliente, JSON.stringify(pro));
            console.log(pro);
            return true;
        }else{
            pro = [data];
            localStorage.setItem(facturaCliente, JSON.stringify(pro));
            return true;
        }
    } catch (error) {
        console.error("Error al guardar el procedimiento:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text:
            error.response?.data?.message ||
            "Hubo un error al guardar el procedimiento",
        });
        return false;
    }
}

export const GetProcedimientoFactura = () => {
    try {
        let pro = localStorage.getItem(facturaCliente);
        if(pro) {
            return JSON.parse(pro);
        }else{
            return [];
        }
    } catch (error) {
        console.error("Error al listar el procedimiento:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text:
            error.response?.data?.message ||
            "Hubo un error al listar el procedimiento",
        });
        return [];
    }
}

// eliminamos todo el listado de procedimientos
export const DeleteAllProcedimientoFactura = () => {
    try {
        localStorage.removeItem(facturaCliente);
        return true;
    } catch (error) {
        console.error("Error al eliminar el procedimiento:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text:
            error.response?.data?.message ||
            "Hubo un error al eliminar el procedimiento",
        });
        return false;
    }
}

export const DeleteProcedimientoFactura = (idprocedimiento_cita_paciente) => {
    try {
        let pro = localStorage.getItem(facturaCliente);
        if(pro) {
            pro = JSON.parse(pro);
            pro = pro.filter(p => p.idprocedimiento_cita_paciente !== idprocedimiento_cita_paciente);
            localStorage.setItem(facturaCliente, JSON.stringify(pro));
            return true;
        }else{
            return false;
        }
    } catch (error) {
        console.error("Error al eliminar el procedimiento:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text:
            error.response?.data?.message ||
            "Hubo un error al eliminar el procedimiento",
        });
        return false;
    }
}

export const UpdateProcedimientoFactura = async (data) => {
    try {
        let pro = localStorage.getItem(facturaCliente);
        if(pro) {
            pro = JSON.parse(pro);
            pro = pro.map(p => {
                if(p.id === data.id){
                    return data;
                }else{
                    return p;
                }
            });
            localStorage.setItem(facturaCliente, JSON.stringify(pro));
            return true;
        }else{
            return false;
        }
    } catch (error) {
        console.error("Error al actualizar el procedimiento:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text:
            error.response?.data?.message ||
            "Hubo un error al actualizar el procedimiento",
        });
        return false;
    }
}

export const ActualizarCantidadProcedimientoFactura = async (idprocedimiento_cita_paciente, cantidad) => {
    try {
        let pro = localStorage.getItem(facturaCliente);
        if(pro) {
            pro = JSON.parse(pro);
            pro = pro.map(p => {
                if(p.idprocedimiento_cita_paciente === idprocedimiento_cita_paciente){
                    p.cantidad = cantidad;
                    return p;
                }else{
                    return p;
                }
            });
            localStorage.setItem(facturaCliente, JSON.stringify(pro));
            return true;
        }else{
            return false;
        }
    } catch (error) {
        console.error("Error al actualizar la cantidad del procedimiento:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text:
            error.response?.data?.message ||
            "Hubo un error al actualizar la cantidad del procedimiento",
        });
        return false;
    }
}