

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { ControllerListarCitaId, ControllerListarUltimoSignosVitales, ControllerUpadateCreateSignosVitales } from '../../../Service/Service.citas';
import { GetTokenDecoded } from '../../../utils/storeUsuario';

export default function SignoVitales({historial}) {

    const [ver_citas, setVerCitas] = useState(false);
    const [signosvitales, setSignosVitales] = useState({
        temperatura: "",
        pulso: "",
        frecuencia_respiratoria: "",
        presion: "",
        saturacion_oxigeno: "",
        peso: "",
        talla: "",
        imc: "",
        idclinica: 0,
    });
    
    const CalcularIMC = () => {
        if(signosvitales){
            if(signosvitales.peso === '' || signosvitales.talla === ''){
                return
            }
            let peso = parseFloat(signosvitales.peso)
            let talla = parseFloat(signosvitales.talla)
            let imc = parseFloat(peso / Math.pow(talla, 2))
            setSignosVitales({...signosvitales, imc: imc})
        }
    }
    
    const actualizatSignosVitales = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        let id_cita = urlParams.get("idcita");
        if (id_cita === null)
            return console.log("No se encontro el id de la cita");

        const res = await ControllerUpadateCreateSignosVitales({...signosvitales, 
            idclinica:GetTokenDecoded().idclinica, idcita:id_cita}); 
        if(res && res.success){
            Swal.fire({
                icon: "success",
                title: "Signos vitales actualizados",
                showConfirmButton: false,
                timer: 1500,
            });
            if(res.data.length > 0){
                setSignosVitales(res.data[0]);
            }
        }
    }

      useEffect(() => {
        CalcularIMC();
      }, [signosvitales]); // Ejecuta CalcularIMC cuando peso o talla cambien
    
    useEffect(() => {
        (async () => {
            const path = window.location.pathname;
            if (path === "/paciente_historial") {
                const urlParams = new URLSearchParams(window.location.search);
                let id_paciente = urlParams.get("idpaciente");
                let id_cita = urlParams.get("idcita");
                if (id_paciente === null)
                    return console.log("No se encontro el id del paciente");
                if (id_cita === null)
                    return console.log("No se encontro el id de la cita");

                const signos = await ControllerListarUltimoSignosVitales(id_cita);
                if (signos.success && signos.data.length > 0) {
                    setSignosVitales(signos.data[0]);
                }else{
                    setSignosVitales({
                    temperatura: "",
                    pulso: "",
                    frecuencia_respiratoria: "",
                    saturacion_oxigeno: "",
                    peso: "",
                    talla: "",
                    imc: "",
                    });
                }

                const CitaEstado = await ControllerListarCitaId(id_cita);
                if(CitaEstado.success){
                    setVerCitas(CitaEstado.data.ver_citas);
                }
            }
        })();
    }, [historial]);

  return (
    <div
        className="tab-pane fade "
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
    >
        <div className="row">
        <p>
            <div className="text-star card-subtitle text-muted">
            INGRESO DE SIGNOS VITALES
            </div>
        </p>
        <div className="col-lg-6">
            <div>
            <div className="mb-3">
                <label for="example-search-input" className="form-label">
                Temperatura C°
                </label>
                <input
                    className="form-control"
                    type="text"
                    id="i_c_temperatura"
                    name="i_c_temperatura"
                    autoComplete='off'
                    pattern='[0-9]+([.][0-9]+)?'
                    disabled={!ver_citas}
                    value={signosvitales.temperatura}
                    onChange={(e) =>setSignosVitales({...signosvitales,temperatura:e.target.value})}
                />
            </div>
            <div className="mb-3">
                <label for="example-search-input" className="form-label">
                Pulso/min:
                </label>
                <input
                type="text"
                className="form-control"
                id="i_c_pulso"
                name="i_c_pulso"
                autoComplete='off'
                pattern='[0-9]+'
                disabled={!ver_citas}
                value={signosvitales.pulso}
                onChange={(e) =>setSignosVitales({...signosvitales,pulso:e.target.value})}
                />
            </div>
            <div className="mb-3">
                <label for="example-email-input" className="form-label">
                Peso/kg
                </label>
                <input
                className="form-control"
                type="text"
                id="i_c_peso"
                autoComplete='off'
                pattern='[0-9]+([.][0-9]+)?'
                name="i_c_peso"
                value={signosvitales.peso}
                disabled={!ver_citas}
                onChange={(e) =>setSignosVitales({...signosvitales,peso:e.target.value})}
                />
            </div>
            <div className="mb-3">
                <label for="example-url-input" className="form-label">
                Sat. Oxigeno{" "}
                </label>
                <input
                className="form-control"
                type="text"
                id="i_c_oxigeno"
                autoComplete='off'
                pattern='[0-9]+([.][0-9]+)?'
                name="i_c_oxigeno"
                disabled={!ver_citas}
                value={signosvitales.saturacion_oxigeno}
                onChange={(e) =>setSignosVitales({...signosvitales,saturacion_oxigeno:e.target.value})}
                />
            </div>
            </div>
        </div>

        <div className="col-lg-6">
            <div className="mt-3 mt-lg-0">
            <div className="mb-3">
                <label for="example-month-input" className="form-label">
                Presión Arterial
                </label>
                <input
                className="form-control"
                type="text"
                id="i_c_presion"
                name="i_c_presion"
                autoComplete='off'
                pattern='[0-9]+[/][0-9]+'
                disabled={!ver_citas}
                value={signosvitales.presion}
                onChange={(e) =>setSignosVitales({...signosvitales,presion:e.target.value})}
                />
            </div>
            <div className="mb-3">
                <label for="example-month-input" className="form-label">
                Fr. Respiratoria{" "}
                </label>
                <input
                className="form-control"
                type="text"
                autoComplete='off'
                pattern='[0-9]+([.][0-9]+)?'
                id="i_c_respiratoria"
                name="i_c_respiratoria"
                disabled={!ver_citas}
                value={signosvitales.frecuencia_respiratoria}
                onChange={(e) =>setSignosVitales({...signosvitales,frecuencia_respiratoria:e.target.value})}
                />
            </div>
            <div className="mb-3">
                <label for="example-week-input" className="form-label">
                Talla/cm:
                </label>
                <input
                className="form-control"
                type="text"
                autoComplete='off'
                pattern='[0-9]+([.][0-9]+)?'
                id="i_c_talla"
                name="i_c_talla"
                disabled={!ver_citas}
                value={signosvitales.talla}
                onChange={(e) =>setSignosVitales({...signosvitales,talla:e.target.value})}
                />
            </div>
            <div className="mb-3">
                <label for="example-time-input" className="form-label">
                IMC
                </label>
                <input
                className="form-control"
                type="text"
                id="i_c_imc"
                pattern='[0-9]+([.][0-9]+)?'
                name="i_c_imc"
                disabled={!ver_citas}
                value={signosvitales.imc}
                />
            </div>
            </div>
        </div>
        </div>
        <div className="text-center  mt-3">
        <button
            className="btn btn-primary waves-effect waves-light text-uppercase btnSaveInfo"
            style={{ width: "30%" }}
            type="button"
            onClick={() => actualizatSignosVitales()}
            disabled={!ver_citas}
        >
            Actualizar Signos Vitales
        </button>
        </div>
    </div>
  )
}
