import React from 'react'

export default function Factura() {
  return (
    <>
    <div className="mt-4 mb-4">
    <div className="card"> 
        <div className="card-body">  
            <table id="tb_facturas_lts" className="table align-middle datatable dt-responsive table-check nowrap table-hover" 
                    style={{borderCollapse: "collapse", borderSpacing: "0 8px", width: "100%", fontSize:"14px"}}>
                <thead>                
                <tr className="table-light">
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Clinica</th>                    
                    <th>Cliente</th>
                    <th className='text-center'>Doc</th>
                    <th className='text-center'>Num. Doc</th>
                    <th className='text-center'>Subtotal</th>
                    <th className='text-center'>Impuesto</th>                    
                    <th className='text-center'>Total</th>
                    <th className='text-center'>Estado</th>
                    <th className="text-center"></th>
                </tr>
                </thead>

                <tbody>
                </tbody>

            </table>
        </div>
    </div>
</div>





<div className="modal fade" id="modalFacturaDetalle" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div className="modal-dialog modal-dialog-scrollable modal-xl">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalScrollableTitle">Detalle Factura</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              @include('facturas.factura-detalle')

            </div>
            <div className="modal-footer">
                <a href="#;" className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i></a>
                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                </div>
        </div>
    </div>
</div>

    </>
  )
}
