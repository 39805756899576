import React from 'react';
import Select from 'react-select'; // Importa Select
import CreatableSelect from 'react-select/creatable'; // Importa CreatableSelect

export default function SelectSearchable({ listCompany, handleChange, selectedOption, placeholder, 
  isMulti, create, styles, onInputChange, isDisabled }) {

  const options = listCompany
    .map(company => ({
      value: company.value,
      label: company.label,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  return create ? (
    <CreatableSelect
      className="basic-single"
      classNamePrefix="select"
      isSearchable={true}
      value={selectedOption}
      onChange={handleChange}
      options={options}
    />
  ) : (
    <div style={styles}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        isSearchable={true}
        // apturar lo que se va escribiendo
        onInputChange={onInputChange}
        // cantidad de select que se pueden seleccionar
        isMulti={isMulti ? true : false}
        // disabled={disabled}
        isDisabled={isDisabled ?? false}
      />
    </div>
  );
}
