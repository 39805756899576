import React, { useState } from 'react';

import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import Modal from 'react-bootstrap/Modal';
import Flatpickr from "react-flatpickr";
const events = [
  { title: 'Meeting', start: new Date() }
]


// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

export default function Agenda() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalVisible, setModalVisible] = useState(false);

  const handleNewEventClick = () => {
    document.getElementById('modal-title').innerText = 'Crear Nueva Cita';
    document.getElementById('ag-paciente').disabled = false;
    document.getElementById('ag-paciente').innerHTML = '';
    document.getElementById('dv_info_paciente').style.display = 'none';
    document.getElementById('dv_search_paciente').style.display = 'block';
    document.getElementById('btn-update-event').style.display = 'none';
    document.getElementById('btn-delete-event').style.display = 'none';
    document.getElementById('btn-save-event').style.display = 'block';
    setModalVisible(true);
  };


  const { date } = new Date();
  return (
    <>


      <div className="page-content">


        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Agenda</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="#">Apps</a></li>
                  <li className="breadcrumb-item active">Agenda</li>
                </ol>
              </div>

            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-12">

            <div className="row">
              <div className="col-xl-3 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="">

                      <div className="row">
                        <div className="col-7">
                          <select className="form-select" id="agenda-clinica" onchange="loadCalendario()">

                            <option value="0">Juan</option>
                            <option value="1">Betty</option>
                            <option value="2">David</option>


                          </select>


                        </div>

                        <div className="col-3">
                          <button id="btn-new-event" onClick={handleShow} type="button" className="btn btn-soft-primary waves-effect waves-light">
                            <i className="fas fa-calendar-plus font-size-18 align-middle"></i></button>

                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Crear Nueva Cita
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <form className="needs-validation" name="event-form" id="form-event" novalidate="">
                                <div className="row">



                                  <div className="col-12" id="dv_search_paciente">
                                    <div className="row mb-3">
                                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Paciente</label>
                                      <div className="col-sm-9">
                                        <input type="hidden" id="hidcita" />
                                        <input type="hidden" id="hidpaciente" />
                                        <select className="form-control select2-hidden-accessible" id="ag-paciente" style={{ width: '90%' }}
                                          data-select2-id="select2-data-ag-paciente" tabindex="-1" aria-hidden="true"></select><span
                                            className="select2 select2-container select2-container--default" dir="ltr"
                                            data-select2-id="select2-data-1-y2yi" style={{ width: '90%' }}><span className="selection"><span
                                              className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true"
                                              aria-expanded="false" tabindex="0" aria-disabled="false"
                                              aria-labelledby="select2-ag-paciente-container"
                                              aria-controls="select2-ag-paciente-container"><span className="select2-selection__rendered"
                                                id="select2-ag-paciente-container" role="textbox" aria-readonly="true"
                                                title="Digite el nombre del Paciente"><span
                                                  className="select2-selection__placeholder">Digite el nombre del
                                                  Paciente</span></span><span className="select2-selection__arrow"
                                                    role="presentation"><b role="presentation"></b></span></span></span><span
                                                      className="dropdown-wrapper" aria-hidden="true"></span></span>
                                      </div>
                                    </div>
                                  </div>






                                  <div className="col-12">
                                    <div className="row mb-3">
                                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Doctor</label>
                                      <div className="col-sm-9">
                                        <select className="form-control" id="ag-doctor">
                                          <option value="" selected="" disabled="">Seleccione un Medico</option>
                                          <option value="347">Prueba Odonto</option>
                                          <option value="348">PruebaDoc</option>
                                          <option value="346">Stefano Tumbaco</option>
                                        </select>

                                      </div>
                                    </div>
                                  </div>


                                  <div className="col-12">
                                    <div className="row mb-3">
                                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Fecha</label>
                                      <div className="col-sm-9">
                                        <input className="form-control" type="date" value="2024-04-26" id="ag-fecha-cita" />
                                      </div>
                                    </div>
                                  </div>


                                  <div className="col-12">
                                    <div className="row mb-3">
                                      <label for="example-time-input" className="col-sm-3 col-form-label">Hora</label>
                                      <div className="col-sm-9">
                                        <div className="row">
                                          <div className="col-sm-6">
                                            <input className="form-control" type="time" value="13:45" id="ag-hora-inicio" />
                                          </div>
                                          <div className="col-sm-6">
                                            <input className="form-control" type="time" value="14:00" id="ag-hora-fin" />
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                  <div className="col-12">
                                    <div className="row mb-3">
                                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Tipo Cita</label>
                                      <div className="col-sm-9">
                                        <select className="form-control" id="ag-tipo-cosulta">
                                          <option value="Primera Cita">Primera Cita</option>
                                          <option value="Cita Subsecuente">Cita Subsecuente</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="col-12">
                                    <div className="row mb-3">
                                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Motivo</label>
                                      <div className="col-sm-9">
                                        <textarea className="form-control" rows="2" id="ag-motivo-consulta"></textarea>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <div className="row mb-3">
                                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Estado</label>
                                      <div className="col-sm-9">
                                        <select className="form-control form-select" name="category" id="ag-estado-cita">
                                          <option value="" selected=""> --Select-- </option>
                                          <option value="Sin Confirmar">Sin Confirmar</option>
                                          <option value="Confirmada">Confirmada</option>
                                        </select>
                                        <div className="invalid-feedback">Please select a valid event category</div>
                                      </div>
                                    </div>
                                  </div>


                                </div>
                                <div className="row mt-2">
                                  <div className="col-4">

                                  </div>
                                  <div className="col-8 text-end">
                                    <button type="button" className="btn btn-light me-1" onClick={handleClose}>Cerrar</button>
                                    <button type="button" className="btn btn-success btnSaveInfo" id="btn-save-event"
                                      onclick="saveCita()">Guardar</button>

                                  </div>
                                </div>
                              </form>
                            </Modal.Body>

                          </Modal>


                        </div>
                        <div className="col-2">
                          <div id="dv_spinners-load" className="spinner-border text-primary m-1"
                            role="status" style={{ display: 'none' }}>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>

                        <div className="col-12 mt-2">
                          <select className="form-select" name="agenda-doctores-show"
                            id="agenda-doctores-show" onchange="loadCalendario()"
                            placeholder="This is a placeholder" multiple>
                            <option value="" disabled>Selecciona una Doctor</option>

                            <option value="0">Juan</option>
                            <option value="1">Betty</option>
                            <option value="2">David</option>
                          </select>
                          <Flatpickr options={{ inline: 'true', minDate: "2017-01-01" }} />

                          {/* <Flatpickr
                            data-enable-time
                            value={date}
                            onChange={([date]) => {
                              this.setState({ date });
                            }}
                          /> */}
                        </div>

                      </div>
                    </div>

                    <div id="external-events" className="mt-2">

                      <p className="text-muted">Resumen de Atencion:</p>
                      <div id="dv_resumen"></div>

                    </div>

                    <div className="row justify-content-center mt-5">
                      <div className="col-lg-12 col-sm-6">
                        <img src="assets/images/undraw-calendar.svg" alt="" className="img-fluid d-block" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8">
                <div className="card">
                  <div className="card-body">
                    {/* <div id="calendar"></div> */}
                    <FullCalendar
                      plugins={[dayGridPlugin, timeGridPlugin]}
                      headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                      }}
                      initialView='dayGridMonth'
                      weekends={false}
                      events={events}
                      eventContent={renderEventContent}
                    />
                  </div>
                </div>
              </div>

            </div>

            <div style={{ clear: 'both' }}></div>



            <div className="modal fade" id="event-modal" tabindex="-1">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header py-3 px-4 border-bottom-0">
                    <h5 className="modal-title" id="modal-title">Event</h5>

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>

                  </div>
                  <div className="modal-body p-3">
                    <form className="needs-validation" name="event-form" id="form-event" >
                      <div className="row">
                        <div className="col-12" id="dv_search_paciente">
                          <div className="row mb-3" >
                            <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Paciente</label>
                            <div className="col-sm-9">
                              <input type="hidden" id="hidcita" />
                              <input type="hidden" id="hidpaciente" />
                              <select className="form-control" id="ag-paciente" style={{ width: '90%' }}>
                              </select>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="col-12" id="dv_info_paciente">

                        <div className="" style={{ fontSize: '14px' }}>
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-9">
                                  <span className="text-muted">
                                    <strong>
                                      <i className="fas fa-mobile"></i>
                                    </strong>
                                    <span id="sp_celular"> </span><br />
                                    <span className="text-muted">
                                      <strong>
                                        <i className="fas fa-envelope"></i>
                                      </strong>
                                      <span id="sp_correo"> </span>
                                    </span>

                                  </span>


                                  <div className="col-3">
                                    <button type="button" className="btn btn-sm btn-soft-success waves-effect waves-light" >Historia Clinica</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>


                      <div className="col-12">
                        <div className="row mb-3">
                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Doctor</label>
                          <div className="col-sm-9">
                            <select className="form-control" id="ag-doctor">
                              <option value="" selected disabled>Seleccione un Medico</option>
                              <option value="0">Juan</option>
                              <option value="1">Betty</option>
                              <option value="2">David</option>
                            </select>

                          </div>
                        </div>
                      </div>


                      <div className="col-12">
                        <div className="row mb-3">
                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Fecha</label>
                          <div className="col-sm-9">
                            <input className="form-control" type="date" value="<?= date('Y-m-d')?>" id="ag-fecha-cita" />
                          </div>
                        </div>
                      </div>


                      <div className="col-12">
                        <div className="row mb-3">
                          <label for="example-time-input" className="col-sm-3 col-form-label">Hora</label>
                          <div className="col-sm-9">
                            <div className="row">
                              <div className="col-sm-6">
                                <input className="form-control" type="time" value="13:45" id="ag-hora-inicio" />
                              </div>
                              <div className="col-sm-6">
                                <input className="form-control" type="time" value="14:00" id="ag-hora-fin" />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>


                      <div className="col-12">
                        <div className="row mb-3">
                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Tipo Cita</label>
                          <div className="col-sm-9">
                            <select className="form-control" id="ag-tipo-cosulta">
                              <option value="Primera Cita">Primera Cita</option>
                              <option value="Cita Subsecuente">Cita Subsecuente</option>
                            </select>
                          </div>
                        </div>
                      </div>


                      <div className="col-12">
                        <div className="row mb-3">
                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Motivo</label>
                          <div className="col-sm-9">
                            <textarea className="form-control" rows="2" id="ag-motivo-consulta"></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row mb-3">
                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Estado</label>
                          <div className="col-sm-9">
                            <select className="form-control form-select" name="category" id="ag-estado-cita">
                              <option value="" selected> --Select-- </option>
                              <option value="Sin Confirmar">Sin Confirmar</option>
                              <option value="Confirmada">Confirmada</option>
                            </select>
                            <div className="invalid-feedback">Please select a valid event category</div>
                          </div>
                        </div>
                      </div>


                    </form>
                  </div>
                  <div className="row mt-2">
                    <div className="col-4">
                      <button type="button" className="btn btn-danger" id="btn-delete-event" style={{ display: 'none' }}>Eliminar</button>
                    </div>
                    <div className="col-8 text-end">
                      <button type="button" className="btn btn-light me-1" data-bs-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-success btnSaveInfo" id="btn-save-event" >Guardar</button>
                      <button type="button" className="btn btn-info btnSaveInfo" id="btn-update-event" style={{ display: 'none' }} >Actualizar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>


    </>
  )
}

