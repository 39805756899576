import { cantidadFailureToast, dosisFailureToast, posologiaFailureToast } from "./ModalRecetaToast";

export const validateFormReceta = (form) => {
  const { posologia, cantidad, dosis } = form; 

  if (typeof posologia === 'string' && posologia.length === 0) {
    posologiaFailureToast();
    return false;
  } else 
  if (typeof cantidad === 'string' && cantidad.length === 0) {
    cantidadFailureToast();
    return false;
  } else 
  if (typeof dosis === 'string' && dosis.length === 0) {
    dosisFailureToast();
    return false;
  }

  return true;
}