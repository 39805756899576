import React, { useEffect } from 'react'
import IngresoConsulta from './IngresoConsulta'

export default function InfoMedicina() {
    useEffect(() => {
        console.log("InfoMedicina")
    }, [])
  return (
    <>
    <div className="row">
    <div className="col-12">
       
        
        <ul className="nav nav-tabs justify-content-star " role="tablist">
            <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#tab-med-ing-consultas" role="tab">
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">INGRESO CONSULTA</span>    
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-med-consultas" role="tab" onclick="getLstConsultas()">
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">CONSULTAS</span>    
                </a>
            </li>
            {/* <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#messages" role="tab">
                    <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                    <span className="d-none d-sm-block">PRESUPUESTOS</span>    
                </a>
            </li> */}
        </ul>
       

        <div className="tab-content p-3 text-muted">

            <div className="tab-pane active" id="tab-med-ing-consultas" role="tabpanel">               
                <IngresoConsulta />
            </div>

            <div className="tab-pane" id="tab-med-consultas" role="tabpanel">
                
                <div className="row mb-4">
                    <div className="col-sm-11">
                        <h6 className="card-title">Listado de Consultas de Paciente</h6>            
                        <p className="card-title-desc"></p>
                    </div>
                    <div className="col-sm-1">
                        <div  className="spinner-border sp_loading_paciente text-primary m-1" role="status" style={{display: "none"}}>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>     

                <div className="table" >
                    <table id="tb_consultas_pacientes" className="table mb-0" style={{fontSize: "13px"}}>

                        <thead className="table-light">
                            <tr>
                                <th>#</th>
                                <th>Fecha</th>
                                <th>Clinica</th>
                                <th>Doctor</th>
                                {/* <!--<th><div align='center'>Motivo</div></th>--> */}
                                <th><div align='center'>Signos</div></th>
                                <th><div align='center'>Diagnostico</div></th>
                                <th><div align='center'>Procedimientos</div></th>
                                <th><div align='center'>Receta</div></th>
                                <th><div align='center'>Valor</div></th>
                                <th></th>

                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>


      
    </div>
</div>
    </>
  )
}
