import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ControllerListarPacientesCitas } from "../../Service/Service.paciente";

export default function Inicio() {
  const [infoCita, setInfoCita] = useState(null);
  const [ping, setPing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      // sacar el id del paciente de la url ?idpaciente=1
      const urlParams = new URLSearchParams(window.location.search);
      const idpaciente = urlParams.get("idpaciente");
      const cit = await ControllerListarPacientesCitas(idpaciente);
      if (cit.success) {
        setInfoCita(cit.data);
      }
    })();
  }, []);

  const handleConsultarPacienteCita = (idcita) => {
    const urlParams = new URLSearchParams(window.location.search);
    const idpaciente = urlParams.get("idpaciente");
    let path = `/paciente_historial?idpaciente=${idpaciente}&idcita=${idcita}`;
    window.history.pushState({}, "", path);
    document.getElementById("medicina-tab").click();
    dispatch({
      type: "SET_HISTORIAL",
      payload: !ping,
    });
  };

  const handleVerDetalleCita = (idcita) => {
    window.location.href = `/detalle-cita?idcita=${idcita}`;
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-wrap gap-2 font-size-16">
                <div className="col-sm order-2 order-sm-1 ">
                  <div
                    className=""
                    style={{
                      width: "100%",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {infoCita &&
                      infoCita.multimedia.map((multimedia, index) => {
                        if (multimedia.ruta === "foto_paciente") {
                          return (
                            <img
                              key={index + 1}
                              src={multimedia.url}
                              alt=""
                              className="img-fluid rounded-circle d-block"
                              width={100}
                            />
                          );
                        }
                      })}
                  </div>

                  <div className="text-center">
                    <span className="text-muted font-size-13 mb-2">
                      {infoCita && infoCita.nombres_completos}
                    </span>
                    <br />
                    <span className="text-muted font-size-13 mb-2">
                      {infoCita && infoCita.celular}
                      <i className="fas fa-mobile-alt"></i>
                    </span>
                    <br />
                    <span className="text-muted font-size-13 mb-2">
                      {infoCita && infoCita.email}
                      <i className="far fa-envelope"></i>
                    </span>
                  </div>
                  <br />
                  {/* <div className="text-center">
                            <button className="btn btn-sm btn-success" onclick="getInfoPaciente()">EDITAR</button>
                        </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-3">Listado de Citas</h5>
              <small className="text-muted">Ultimas 5 citas</small>

              <div>
                <table
                  id="tb_resumen_citas"
                  className="table table-sm"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  <thead>
                    <tr>
                      <td>Fecha</td>
                      <td>Hora</td>
                      <td>Doctor</td>
                      <td>Estado</td>
                      <td>Opcion</td>
                    </tr>
                  </thead>
                  <tbody>
                    {infoCita &&
                      infoCita.citas.map((cita, index) => (
                        <tr key={index}>
                          <td>{cita.fecha_inicio}</td>
                          <td>{cita.hora_inicio}</td>
                          <td>{cita.doctores.nombre}</td>
                          <td>
                            <span
                              className={`${
                                cita.estado_cita.estado === "Sin Confirmar"
                                  ? "badge bg-primary"
                                  : cita.estado_cita.estado === "Confirmado"
                                  ? "badge bg-success"
                                  : cita.estado_cita.estado ===
                                    "En sala de espera"
                                  ? "badge bg-info"
                                  : cita.estado_cita.estado === "Reagendado"
                                  ? "badge bg-warning"
                                  : ""
                              } px-2 py-1 mt-1`}
                            >
                              {cita.estado_cita.estado}
                            </span>
                          </td>
                          <td>
                            <span
                              className="btn btn-sm btn-soft-primary cursor-pointer"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleConsultarPacienteCita(cita.idcita)
                              }
                            >
                              {
                                cita.ver_citas ? (
                                  <span 
                                    title="Consultar cita"
                                  >
                                    Consultar
                                    <i className="fas fa-eye mx-1"></i>
                                  </span>
                                ) : (
                                  <span
                                    title="Ver detalle de la cita"
                                  >
                                    ver
                                    <i className="fas fa-eye mx-1"></i>
                                  </span>
                                )
                              }
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-3">Listado de Tratamientos</h5>
              <small className="text-muted">Ultimos 10 </small>

              <div>
                <table
                  id="tb_resumen_tratamientos"
                  className="table table-sm"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  <thead>
                    <tr>
                      <td
                        style={{
                          width: "25%",
                        }}
                      >
                        Tratamiento
                      </td>
                      <td>Fecha</td>
                      <td>Doctor</td>
                      <td>Estado</td>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4">
          <div className="card bg-danger border-danger text-white-50">
            <div className="card-body">
              <div>
                <h5 className="mb-2 text-white"> TOTAL DEUDA</h5>
              </div>
              <div>
                {" "}
                <h3 id="resumen-deuda" className="text-end text-white">
                  {" "}
                  0.00{" "}
                </h3>{" "}
              </div>
            </div>
          </div>

          <div className="card bg-success border-success text-white-50">
            <div className="card-body">
              <div>
                <h5 className="mb-2 text-white"> SALDO A FAVOR</h5>
              </div>
              <div>
                {" "}
                <h3 id="resumen-saldo-favor" className="text-end text-white">
                  {" "}
                  0.00{" "}
                </h3>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
