import { BmHttp } from "../utils/Global";

export const ControllerCreateSignos = async (signos) => {
    try {
        if(signos.idsignosvitales > 0){
            return ControllerUpdateSignos(signos)
        }
        const { data } = await BmHttp().post('signosvitales_create', signos)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerListarSignos = async (idcita) => {
    try {
        const { data } = await BmHttp().get(`signosvitales_all?idcita=${idcita}`)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerUpdateSignos = async (signos) => {
    try {
        const { data } = await BmHttp().put('signosvitales_update', signos)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerDeleteSignos = async (idsignos) => {
    try {
        const { data } = await BmHttp().delete('signosvitales_delete/'+idsignos)
        return data
    } catch (error) {
        console.log(error);
    }
}