import { diagonosticoFailureToast, tipoDiagonosticoFailureToast } from "./ModalPacienteToast";

export const validateFormPaciente = (form) => {
  const { iddiagnostico, typo_diagnostico } = form;
  
  if (typeof iddiagnostico === 'number' && iddiagnostico === 0) {
    diagonosticoFailureToast();
    return false;
  } else 
  if (typeof typo_diagnostico === 'string' && typo_diagnostico.length === 0) {
    tipoDiagonosticoFailureToast();
    return false;
  } 
  
  return true;
}
