import Swal from "sweetalert2";

export const diagonosticoFailureToast = () => {
  Swal.fire({
    title: 'Error en el formulario',
    text: 'Debe seleccionar un diagnóstico',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}

export const tipoDiagonosticoFailureToast = () => {
  Swal.fire({
    title: 'Error en el formulario',
    text: 'Debe seleccionar un tipo de diagnóstico',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}

export const registerPacienteSuccessToast = () => {
  Swal.fire({
    title: 'Registro exitoso',
    text: 'Los datos han sido guardados exitosamente',
    icon: 'success',
    confirmButtonText: 'Aceptar'
  });
}

export const registerPacienteFailureToast = () => {
  Swal.fire({
    title: 'Registro fallido',
    text: 'Ha ocurrido un error al guardar los datos, intente más tarde',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}