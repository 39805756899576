import React from "react";

export default function ProcOdontologiaCreate() {
  return (
    <section>
       <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">

              <form id="form-create-procedimiento-odont">


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombre Procedimiento</label>
                  <div className="col-sm-9">
                    <input type="hidden" id="hidprocedimiento" name="hidprocedimiento" />
                      <input type="text" className="form-control" id="procedimiento-nombre" name="procedimiento-nombre" required />
                      </div>
                  </div>




                  <div className="row mb-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Tipo Procedimiento </label>
                    <div className="col-sm-9">
                    <select className="form-control" id="tipo-procedimiento-med" name="tipo-procedimiento-med" required=""><option value="82">Procedimientos</option><option value="83">Especialidad</option><option value="84">Proce Med</option><option value="87">Simple</option><option value="89">Simple</option><option value="101">Prueba Creac</option></select>
                    </div>
                  </div>


                  <div className="row mb-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Duración </label>

                    <div className="col-sm-9">
                      <input type="text" className="form-control" name="duracion-proced" id="duracion-proced" />
                    </div>
                  </div>


                  <div className="row mb-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Precio</label>
                    <div className="col-sm-9">
                      <input type="text" className="form-control" id="precio-proced" name="precio-proced" required/>
                    </div>
                  </div>


                  <div className="row mb-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Activo</label>
                    <div className="col-sm-9">
                      <input type="checkbox" id="estado_proced" name="estado_proced" switch="none" value="1" checked />
                      <label for="estado_proced" data-on-label="On" data-off-label="Off"></label>

                    </div>
                  </div>


                  <div className="text-center">
                    <a href="javascript:;" onclick="openModalProcOdonto()" className="btn btn-danger btnCloseCanvas">Cancelar</a>
                    <button type="submit" className="btn btn-primary btnSaveInfo">Guardar</button>
                  </div>

              </form>
            </div>
          </div>
        </div> 
      </div> 

    </section>
  );
}
