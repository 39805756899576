import { BmHttp } from "../utils/Global";

export const ControllerCreateDescuento = async (descuento) => {
    try {
        if(descuento.id_descuento > 0){
            return ControllerUpdateDescuento(descuento)
        }
        const { data } = await BmHttp().post('create_descuento', descuento)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerListardescuentos = async () => {
    try {
        const { data } = await BmHttp().get(`listar_descuentos`)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerListardescuento = async (id_descuento) => {
    try {
        const { data } = await BmHttp().post(`listar_descuento`,{id_descuento})
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerUpdateDescuento = async (descuento) => {
    try {
        const { data } = await BmHttp().put('update_descuento', descuento)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerDeletedescuento = async (id_descuento) => {
    try {
        const { data } = await BmHttp().delete('delete_descuento'+{id_descuento})
        return data
    } catch (error) {
        console.log(error);
    }
}