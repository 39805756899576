import { BmHttp } from "../utils/Global"

export const ListarDirectorios = async (idclinica, ruta) => {
    try {
        if(ruta != null){
            const { data } = await BmHttp().get(`listar_directorios_ruta?idclinica=${idclinica}&ruta=${ruta}`)
            return data
        }else{
            const { data } = await BmHttp().get(`listar_directorios?idclinica=${idclinica}`)
            return data
        }
    } catch (error) {
        console.log(error)
    }
}