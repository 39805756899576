import React from 'react';
import calendario from "../../Assets/images/brands/calendar.png";
import dropbox from "../../Assets/images/brands/dropbox.png";
import facturas from "../../Assets/images/brands/facturas.png";
import mail from "../../Assets/images/brands/mail_chimp.png";
import pacientes from "../../Assets/images/brands/pacientes.png";
import slack from "../../Assets/images/brands/slack.png";
//import logoDesktop from "../../Assets/images/logo.svg";
import logoDesktop from "../../Assets/images/logo-A1.png";
import avatarDefault from "../../Assets/images/users/default-avatar.jpg";
import useAuth from '../../hook/useAuth';
import { GetTokenDecoded } from '../../utils/storeUsuario';

export default function AdminNavbar({collaseSidebar, setCollapseSidebar, toggleMenu}) {

    const { logout } = useAuth();

    return (
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box ">
                            <a href="#;" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoDesktop} alt="" width={24} />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDesktop} alt="" width={150} /> 
                                        {/*
                                            collaseSidebar ?
                                                    <span className="logo-txt">C - E - Colon</span>
                                                :
                                                null
                                        */}
                                </span>
                            </a>

                            <a href="#;" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoDesktop} alt="" width={24} />
                                </span>

                                {collaseSidebar ?
                                    <span className="logo-lg">
                                        <img src={logoDesktop} alt="" width={24} />
                                    </span>
                                    :
                                    <span className="logo-lg">
                                        <img src={logoDesktop} alt="" width={24} /> <span className="logo-txt">SmartMedic</span>
                                    </span>
                                }
                            </a>
                        </div>

                        <button type="button" className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
                            // onClick={() => setCollapseSidebar(!collaseSidebar)}
                            onClick={toggleMenu}
                        >
                            <i className="fa fa-fw fa-bars"></i>
                        </button>

                    </div>

                    <div className="d-flex">

                        <div className="dropdown d-inline-block d-lg-none ms-2">
                            <button type="button" className="btn header-item" id="page-header-search-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i data-feather="search" className="icon-lg"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown">

                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..." aria-label="Search Result" />

                                            <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="dropdown d-none d-sm-inline-block">
                            <button type="button" className="btn header-item" id="mode-setting-btn">
                                <i  className="fa fa-moon-o layout-mode-dark "></i>
                                <i  className="fa fa-sun-o layout-mode-light fa "></i>
                            </button>
                        </div>

                        <div className="dropdown d-none d-lg-inline-block ms-1">
                            <button type="button" className="btn header-item"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-large"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
                                <div className="p-2">
                                    <div className="row g-0">
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="/gestion_citas">
                                                <img src={calendario} alt="Github" />
                                                <span>Agenda</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="/pacientes">
                                                <img src={pacientes} alt="bitbucket" />
                                                <span>Pacientes</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="/factura-crear">
                                                <img src={facturas} alt="dribbble" />
                                                <span>Facturas</span>
                                            </a>
                                        </div>
                                    </div>
                                    {/*<div className="row g-0">
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="#;">
                                                <img src={dropbox} alt="dropbox" />
                                                <span>Dropbox</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="#;">
                                                <img src={mail} alt="mail_chimp" />
                                                <span>Mail Chimp</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="#;">
                                                <img src={slack} alt="slack" />
                                                <span>Slack</span>
                                            </a>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                    

                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item bg-soft-light border-start border-end" id="page-header-user-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="rounded-circle header-profile-user" src={avatarDefault}
                                    alt="Header Avatar" />
                                <span className="d-none d-xl-inline-block ms-1 fw-medium"> {
                                    GetTokenDecoded().full_name
                                } </span>
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="apps-contacts-profile.html"><i className="mdi mdi-face-profile font-size-16 align-middle me-1"></i> Profile </a>
                                <a className="dropdown-item" href="auth-lock-screen.html"><i className="mdi mdi-lock font-size-16 align-middle me-1"></i> Actualizar Clave</a>
                                {/* cambiar de cuenta */}
                                <a className="dropdown-item" href="#;"><i className="mdi mdi-switch font-size-16 align-middle me-1"></i> Cambiar/Clinica </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#;" 
                                    onClick={() =>
                                        logout()
                                    }
                                ><i className="mdi mdi-logout font-size-16 align-middle me-1"></i> Logout</a>
                            </div>
                        </div>

                    </div>
                </div>
            </header>
    )
}
