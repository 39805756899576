import React from 'react'

export default function Separador({text}) {
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px',
    }}
    >
        <div
            style={{
                border: '0.5px solid #ccc',
                width: '35%',
            }}
        />
        <span
            style={{
                padding: '0 10px',
                fontSize: '14px',
                color: '#333',
                whiteSpace: 'nowrap',
                width: '30%',
                textAlign: 'center',
            }}
        >
            {text}
        </span>
        <div
            style={{
                border: '0.5px solid #ccc',
                width: '35%',
            }}
        />
    </div>
  )
}
