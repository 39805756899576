
import React from 'react'
import Swal from 'sweetalert2'
import { ControllerCreateCliente } from '../../Service/Service.cliente'

export default function CrearCliente({cliente, setCliente, handleCloseCliente, setPing, ping}) {

  const GuardarCliente = async () => {
    const response = await ControllerCreateCliente(cliente)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Cliente Creado',
        text: response.message
      })
      handleCloseCliente()
      setPing(!ping)
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: response.message
      })
    }
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <form>
          <div className='form-group'>
            <label>Nombre</label>
            <input type='text' className='form-control' value={cliente.nombre} onChange={(e) => setCliente({...cliente, nombre: e.target.value})} />
          </div>
          <div className='form-group'>
            <label>Tipo de Identificación</label>
            <select className='form-control' value={cliente.tipoID} onChange={(e) => setCliente({...cliente, tipoID: e.target.value})}>
              <option value='CC'>Cédula de Ciudadanía</option>
              <option value='CE'>Cédula de Extranjería</option>
              <option value='NIT'>NIT</option>
              <option value='TI'>Tarjeta de Identidad</option>
            </select>
          </div>
          <div className='form-group'>
            <label>Identificación</label>
            <input type='text' className='form-control' value={cliente.identificacion} onChange={(e) => setCliente({...cliente, identificacion: e.target.value})} />
          </div>
          {/* Es Juridico */}
          <div className='form-switch my-2'>
            <div className='form-check'>
              <label>Es Jurídico</label>
              <input type='checkbox' className='form-check-input' checked={cliente.es_juridico} onChange={(e) => setCliente({...cliente, es_juridico: e.target.checked})} />
            </div>
          </div>

          <div className='form-group'>
            <label>Dirección</label>
            <input type='text' className='form-control' value={cliente.direccion} onChange={(e) => setCliente({...cliente, direccion: e.target.value})} />
          </div>
          <div className='form-group'>
            <label>Correo</label>
            <input type='text' className='form-control' value={cliente.correo} onChange={(e) => setCliente({...cliente, correo: e.target.value})} />
          </div>
          <div className='form-group'>
            <label>Celular</label>
            <input type='text' className='form-control' value={cliente.celular} onChange={(e) => setCliente({...cliente, celular: e.target.value})} />
          </div>
          <div className='form-group'>
            <label>Teléfono</label>
            <input type='text' className='form-control' value={cliente.telefono} onChange={(e) => setCliente({...cliente, telefono: e.target.value})} />
          </div>
          <div 
            className='form-group d-flex justify-content-center mt-4'
          >
            <button type='button' className='btn btn-primary mx-1' onClick={GuardarCliente}>Guardar</button>
            <button type='button' className='btn btn-secondary mx-1' onClick={handleCloseCliente}>Cancelar</button>
          </div>
        </form>
      </div>

    </div>
  )
}
