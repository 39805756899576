import { BmHttp } from "../utils/Global";


export const ListarTiposSeguros = async (idclinica) => {
    try {
        const {data} = await BmHttp().get("listar_tipo_seguro?idclinica="+idclinica);
        return data;
    } catch (error) {
        console.error(error);
    }
}