import { BmHttp } from "../utils/Global";

export const GetEspecialidades = async () => {
    try {
        const { data } = await BmHttp().get('especialidades_all') //se comenta para traer especialidades de la clinica;
        //const { data } = await BmHttp().get(`ListarEspecialidadesClinica/${idclinica}`);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const EspecialidadClinica = async (idclinica) => {
    try {
        const { data } = await BmHttp().get(`especialidades_clinica/${idclinica}`);
        return data
    } catch (error) {
        console.error(error);
    }
}

export const ControllerEliminarEspecialidad = async (idespecialidad) => {
    try {
        const { data } = await BmHttp().delete(`eliminar_especialidad?idespecialidad=${idespecialidad}`);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ActivarDesactivarEspecialidad = async (doctor) => {
    try {
        console.log("doctor: ", doctor)
        const { data } = await BmHttp().post(`especialidades_clinica`, {
            idclinica_especialidad: Number(doctor.idclinica_especialidad),
            idespecialidad: Number(doctor.idespecialidad),
            idclinica: Number(doctor.idclinica),
            estado: Boolean(doctor.estado),
            icono: doctor.icono,
            intervalo_tiempo: doctor.intervalo_tiempo,
            precio: doctor.precio
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ListarEspecialidadClinicaActivas = async (idclinica) => {
    try {
        const { data } = await BmHttp().get(`especialidades_activas_clinica/${idclinica}`);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ListarDoctoresEspecialidadClinica = async (idespecialidad, idclinica) => {
    try {
        const { data } = await BmHttp().get(`especialidades_activas_doctores_clinica/${idespecialidad}/${idclinica}`);
        return data;
    } catch (error) {
        console.error(error);
    }
}