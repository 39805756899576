import React from 'react';

const ContextMenu = ({ visible, position, options, onClose }) => {
  if (!visible) return null; // No renderizar si el menú no es visible

  return (
    <div
      style={{
        position: 'absolute',
        top: (position.y -250),
        left: (position.x -580),
        backgroundColor: 'white',
        border: '1px solid #ccc',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
        zIndex: 1000,
        borderRadius: '4px',
      }}
      onClick={(e) => e.stopPropagation()} // Evita cerrar el menú al hacer clic en él
    >
      {options.map((option, idx) => (
        <div
          key={idx}
          onClick={(e) => {
            e.stopPropagation(); // Evita cerrar el menú al seleccionar una opción
            option.action();
            onClose();
          }}
          style={{
            padding: '8px 16px',
            cursor: 'pointer',
            borderBottom: idx < options.length - 1 ? '1px solid #eee' : 'none',
          }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default ContextMenu;
