import React, { useEffect, useMemo, useState } from "react";
import Login from "./Page/Auth/Login";
// import Register from "./Page/Auth/Register";

import { Provider } from "react-redux";
import AuthContext from "./context/AuthContext";
import Admin from "./Layout/Admin";
import store from "./redux/store";
import { GetToken, removeDatosUsuario } from "./utils/storeUsuario";

// import AdminNavbar from "./Components/Navbars/AdminNavbar";
// import "../public/functions/globals.js";
// import "../public/js/app.js";
// import "../public/js/vanilla-toast.min.js";
// import "../public/libs/bootstrap/js/bootstrap.bundle.min.js";
// import "../public/libs/feather-icons/feather.min.js";
// import "../public/libs/flatpickr/flatpickr.min.js";
// import "../public/libs/imask/imask.min.js";
// import "../public/libs/jquery/jquery.min.js";
// import "../public/libs/metismenu/metisMenu.min.js";
// import "../public/libs/node-waves/waves.min.js";
// import "../public/libs/pace-js/pace.min.js";
// import "../public/libs/select2/js/select2.min.js";
// import "../public/libs/simplebar/simplebar.min.js";
// import "../public/libs/sweetalert2/sweetalert2.min.js";




export default function Rpp() {
  const [auth, setAuth] = useState(undefined);
  const [ReloadUser, setReloadUser] = useState(false);


  useEffect(() => {
    (() => {
      const user = GetToken();
      if (user != null) {
        setAuth(user.token);
      } else {
        setAuth(null);
      }
      setReloadUser(false);
    })();
  }, [ReloadUser]);
  const login = (user) => {
    setAuth(user);
    setReloadUser(true);
    // setTimeout(() => {
    //   window.location.href = "/";
    // }, 800);
  }
  const logout = () => {
    removeDatosUsuario();
    setAuth(null);
    setReloadUser(!ReloadUser);
    window.location.href = "/";
  }

  const authData = useMemo(
    () => ({
      auth,
      login,
      logout,
      setReloadUser,
    }),
    [auth]
  )
  if (auth === undefined) return null;
  return (
    <Provider store={store}>
      <AuthContext.Provider value={authData}>
        {
          !auth ? (
            <>
              <Login />
              {/* <Register /> */}
            </>
          ) : (
            <Admin />
          )
        }
      </AuthContext.Provider>
    </Provider>
  )
}
// <Register/>
// <AdminNavbar/>