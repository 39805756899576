

import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Swal from 'sweetalert2';
import { ControllerDeletedescuento, ControllerListardescuentos } from '../../../Service/Service.descuentos';
import Alert from '../../Alert/Alert';
import Speener from '../../Speener/Speener';
import CreateDescuento from './CreateDescuento';


export default function Decuentos({tabs}) {
    const [show, setShow] = useState(false);
    const [showPlan, setShowPlan] = useState(false);
    const [showCreaPlan, setShowCreaPlan] = useState(false);

    const [tipoAlert, setTipoAlert] = useState('')
    const [alertMensaje, setAlertMensaje] = useState('')
    const [editDescuento, setEditDescuento] = useState(null)
    const [listDescuento, setListDescuento] = useState([])
    const [loading, setLoading] = useState(false)
    const [ping, setPing] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        (async()=>{
          setLoading(true)
          const response = await ControllerListardescuentos()
          if(response){
            setListDescuento(response.data)
            setLoading(false)
          }
        })()
    }, [ping, tabs.clinicas])

    const EditarDescuento= (descuento) => {
      setEditDescuento(descuento)
      handleShow()
    }
    
    const Deletedescuento = async(idseguro) => {
        Swal.fire({
            title: 'Estas seguro de eliminar esta clinica?',
            text: "No podras revertir esta accion!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              const response = await ControllerDeletedescuento(idseguro)
              if(response){
                setPing(!ping)
                setTipoAlert('success')
                setAlertMensaje('Clinica eliminda con exito')
              }else{
                setTipoAlert('danger')
                setAlertMensaje('Ocurrio un error en la operacion por favor intente nuevamente')
              }
              setTimeout(()=>{
                setTipoAlert('')
                setAlertMensaje('')
              },1500)
            }
          })
    }

  return (
    <section>
      <Alert
        tipo_alert={tipoAlert}
        msj={alertMensaje}
      />
      <div className="row">
        <div className="col-12">

          <div className="page-title-box d-sm-flex align-items-center justify-content-between ">
            <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}>
                <i className="bx bx-plus me-1"></i> Crear Descuento</button>
            <Speener loading={loading} />
          </div>

          <table id="tb_clinicas" className="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th>Nombre</th>
                <th>Fecha Inicio</th>
                <th>Fecha Vencimiento</th>
                <th>Porcentaje Descuento</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                listDescuento.map((decuento, index) => (
                  <tr key={index}>
                    <td align="center">{index + 1}</td>
                    <td>{decuento.nombre}</td>
                    <td>{decuento.fecha_inicio}</td>
                    <td>{decuento.fecha_vencimiento}</td>
                    <td>{decuento.porcentaje_descuento}</td>
                    <td align="left">
                      <div className="btn-group"><button type="button"
                        className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#;" onClick={()=>EditarDescuento(decuento)}>Editar</a>
                          <a className="dropdown-item" href="#;" onClick={()=>Deletedescuento(decuento.id_descuento)}>Eliminar</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Seguro
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateDescuento
            setPing={setPing}
            ping={ping}
            handleClose={handleClose}
            show={show}
            editDescuento={editDescuento}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  )
}
