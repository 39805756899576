import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { CrearClinica } from "../../Service/Serive.clinica";
import { ObtenerCompanies } from "../../Service/Service.compania";
import { SubirMedia } from "../../Service/ServiceExternal";
import Alert from "../Alert/Alert";
import SelectSearchble from "../SelectSeachble/SelectSearchble";

export default function ClinicaCreate({editClinica, handleClose, setPing, ping, show}) {


  const [alertcompany, setAlertCompany] = useState(false)
  const refDiv = useRef(null)

  const [send, setSend] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [msj, setMsj] = useState('')
  const [listCompany, setListCompany] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);
  const [logo, setLogo] = useState(null);
  const [selloclinica, setSelloclinica] = useState(null);
  const [sello, setSello] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  
  const { register, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: editClinica || {},
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    setAlertCompany(false)
  };


  useEffect(() => {
    // Cargar las compañías
    (async () => {
      const response = await ObtenerCompanies();
      if (response) {
        const options = response.map(company => ({
          value: company.idcompany,
          label: company.company,
        }));
        setListCompany(options);
        if (editClinica?.idcompany) {
          const selected = options.find(opt => opt.value === editClinica.idcompany);
          setSelectedOption(selected);
          setLogo(editClinica.logo);
          setSelloclinica(editClinica.selloclinica);
          setSello(editClinica.sello);
        }

      }
    })();
  }, [editClinica]);

  const onSubmit = handleSubmit(async (data) => {
    if (!selectedOption) {
      setAlertCompany(true);
      return;
    }

    setSend(true);
    data.idcompany = selectedOption.value;
    // Subir archivos
    for (const field of ["logo", "selloclinica", "sello"]) {
      if (data[field] && data[field].length > 0) {
        const url = await SubirMedia(data[field][0],  null, null, 0, 'foto_logo');
        data[field] = url;
      } else {
        data[field] = "";
      }
    }

    // validar logo, selloclinica, sello
    if (!data.logo) {
      data.logo = logo;
    }

    if (!data.selloclinica) {
      data.selloclinica = selloclinica;
    }
    
    if (!data.sello) {
      data.sello = sello;
    }

    const response = await CrearClinica(data);
    console.log("response: ", response);
    if (response) {
      setAlertType('success');
    } else {
      setAlertType('error');
    }

    setSend(false);
    refDiv.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      setAlertType('');
      setPing(!ping);
      handleClose();
    }, 800);
  });

  return (
    <section>
        <Alert
          tipo_alert={msj || alertType}
          type={msj || alertType}
        />
      <div className="row">
      <div ref={refDiv} />
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form id="form-clinica-create" onSubmit={onSubmit}>
                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Compania</label>
                  <div className="col-sm-9">
                    <SelectSearchble 
                      listCompany={listCompany} 
                      handleChange={handleChange} 
                      selectedOption={selectedOption} 
                      placeholder="Seleccione Compania"
                    />
                    <Alert
                      tipo_alert="danger"
                      type={alertcompany ? 'select' : ''}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Codigo Clinica</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("codigo", {
                      required: false
                    })} id="codigo" />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombre Clinica *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                    {...register("nombre",{
                      required: true,
                      minLength: 3,
                      maxLength: 100,
                      // value: clinica.nombre
                    })} id="nombre" />
                    <Alert
                      tipo_alert="danger"
                      msj={errors.nombre?.message}
                      type={errors.nombre?.message || errors.nombre?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Direccion *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("direccion",{
                      required: true,
                      minLength: 10,
                      maxLength: 100
                    })} id="direccion" />
                    <Alert
                      tipo_alert="danger"
                      type={errors.direccion?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Telefono</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="telefono-clinica" id="telefono-clinica" 
                      {...register("telefono",{
                        required: false,
                        minLength: 10,
                        maxLength: 10,
                        type: 'text'
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.telefono?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Celular *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("celular",{
                      required: true,
                      minLength: 10,
                      maxLength: 12,
                      type: 'number'
                    })} id="celular" />
                    <Alert
                      tipo_alert="danger"
                      type={errors.celular?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Provincia</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="provincia" id="provincia" 
                      {...register("provincia",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.provincia?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Ciudad</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="ciudad" id="ciudad" 
                      {...register("ciudad",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.ciudad?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Web</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="web" id="web" 
                      {...register("web",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.web?.type}
                    />                    
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-9">
                    <input type="email" className="form-control" name="email" id="email"
                      {...register("email",{
                        required: true,
                        minLength: 5,
                        maxLength: 100,
                        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.email?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Horario de inicio</label>
                  <div className="col-sm-4">
                    <input type="time" className="form-control" name="horario_inicio" id="horario_inicio"
                      {...register("horario_inicio",{
                          required: true,
                        })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.horario_inicio?.type}
                    />
                  </div>
                  <label for="horizontal-email-input" className="col-sm-1 col-form-label">Fin</label>
                  <div className="col-sm-4">
                    <input type="time" className="form-control" name="horario_fin" id="horario_fin"
                      {...register("horario_fin",{
                        required: true,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.horario_fin?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Pais</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="pais" id="pais" 
                      {...register("pais",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.pais?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Tipo</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="tipo" id="tipo" 
                      {...register("tipo",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.tipo?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Logo</label>
                  <div className="col-sm-9">
                    <input type="file" className="form-control" name="logo" id="logo" 
                      {...register("logo",{
                        required: false,
                      })}
                      onChange={handleFileChange}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.logo?.type}
                    />
                     {editClinica && editClinica.logo ? (
                      <div className="d-flex justify-content-start align-items-center">
                        <div
                          className="me-3"
                          style={{ width: '100px', height: '100px' }}
                        >
                          <span>Imagen actual</span>
                          <img
                            src={editClinica.logo}
                            alt="foto actual"
                            className="img-fluid mt-2"
                            style={{ width: '100px', height: '100px' }}
                          />
                        </div>
                        {previewImage && (
                          <div className="ms-3 "
                            style={{ width: '100px', height: '100px' }}
                          >
                            <span>Nueva imagen</span>
                            <img
                              src={previewImage}
                              alt="nueva foto"
                              className="img-fluid mt-2"
                              style={{ width: '100px', height: '100px' }}
                            />
                          </div>
                        )}
                      </div>
                    ) : previewImage ? (
                      <div className="w-16"
                        style={{ width: '100px', height: '100px' }}
                      >
                        <span>Nueva imagen</span>
                        <img
                          src={previewImage}
                          alt="nueva foto"
                          className="img-fluid mt-2"
                          style={{ width: '100px', height: '100px' }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Sello Clinica</label>
                  <div className="col-sm-9">
                    <input type="file" className="form-control" name="selloclinica" id="selloclinica" 
                      {...register("selloclinica",{
                        required: false,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.selloclinica?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Sello</label>
                  <div className="col-sm-9">
                    <input type="file" className="form-control" name="sello" id="sello" 
                      {...register("sello",{
                        required: false,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.sello?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Ruc *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="ruc" id="ruc" 
                      {...register("ruc",{
                        required: true,
                        minLength: 10,
                        maxLength: 13,
                        type: 'text'
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.ruc?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Representante *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="reprensentante" id="representante"
                      {...register("representante",{
                        required: true,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.representante?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Facebook</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="facebook" id="facebook" 
                      {...register("facebook",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Instagram</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="instagram" id="instagram" 
                      {...register("instagram",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Configuracion</label>
                  <div className="col-sm-9 form-check form-switch">
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexActivo" name="is_activo" 
                         {...register("is_activo",{
                          required: false,
                          defaultChecked: false
                        })
                         }
                      />
                      <label className="form-check-label" for="flexActivo">Estado activo</label>
                    </div>
                    
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexEncuastas" name="encuestas" 
                        {...register("encuestas",{
                          required: false,
                          defaultChecked: false
                        })
                        }
                      />
                      <label className="form-check-label" for="flexEncuastas">Encuestas</label>
                    </div>
                    
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexInventario" name="use_inventario" 
                      {...register("use_inventario",{
                        required: false,
                        defaultChecked: false
                      })
                      }
                      />
                      <label className="form-check-label" for="flexInventario">Inventario</label>
                    </div>
                    
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexContifico" name="use_contifico" 
                      {...register("use_contifico",{
                        required: false,
                        defaultChecked: false
                      })
                      }
                       />
                      <label className="form-check-label" for="flexContifico">Contifico</label>
                    </div>

                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexFacturacion" name="use_fact_electronica" 
                      {...register("use_fact_electronica",{
                        required: false,
                        defaultChecked: false
                      })
                      }
                      />
                      <label className="form-check-label" for="flexFacturacion">Facturacion electronica</label>
                    </div>
                  </div>

                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Establecimiento</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="establecimiento" id="establecimiento" 
                      {...register("establecimiento",{
                        required: false,
                        minLength: 3,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.establecimiento?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Iva</label>
                  <div className="col-sm-9">
                    <select className="form-select" name="porc_iva" id="iva"
                      {...register("porc_iva",{
                        required: false,
                        defaultChecked: 0.00
                      })}
                    >
                      {
                        [0, 12, 15].map((iva, index) => (
                          <option key={index} value={iva}
                            selected={iva === editClinica?.porc_iva ? true : false}
                          >{iva}%</option>
                        ))
                      }
                    </select>
                  </div>
                </div>


                <InputField
                  label="Punto de emision"
                  id="ptoemi"
                  register={register("ptoemi",{
                    required: false,
                    minLength: 3,
                    maxLength: 3,
                  })}
                  error={errors.ptoemi}
                />


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nivel Clinica</label>
                  <div className="col-sm-9">
                    <select className="form-select" name="nivel_clinica" id="nivel_clinica"
                      {...register("nivel_clinica",{
                        required: false,
                        defaultChecked: 0
                      })}
                    >
                      <option value="0">Nivel 0</option>
                      <option value="1">Nivel 1</option>
                      <option value="2">Nivel 2</option>
                      <option value="3">Nivel 3</option>
                      <option value="4">Nivel 4</option>
                    </select>
                  </div>
                </div>

                <InputField
                  label="Ubicacion Google map"
                  id="gmap"
                  register={register("gmap",{
                    required: false,
                    minLength: 5,
                    maxLength: 100,
                  })}
                  error={errors.gmap}
                />

                <div className="text-center">
                  <button  onclick="openModalClinica()" className="btn btn-danger m-1">
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 btnSppinAjax" 
                      style={{
                        display: send ? 'inline-block' : 'none' 
                      }}></i>
                    Guardar
                  </button>
                </div>

              </form>


            </div>
          </div>
        </div>
      </div>


    </section>
  );
}


// Componente reutilizable para inputs
function InputField({ label, id, register, error }) {
  return (
    <div className="row mb-4">
      <label htmlFor={id} className="col-sm-3 col-form-label">{label}</label>
      <div className="col-sm-9">
        <input type="text" className="form-control" id={id} {...register} />
        {error && <Alert tipo_alert="danger" type={error.message} />}
      </div>
    </div>
  );
}