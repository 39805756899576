import React, { useEffect, useState } from 'react';
import { ListarClinicas } from "../../../Service/Serive.clinica";
import { ActivarDesactivarEspecialidad, GetEspecialidades } from "../../../Service/Service.especialidad";
import { SubirMedia } from "../../../Service/ServiceExternal";
import { GetTokenDecoded } from '../../../utils/storeUsuario';
import Alert from "../../Alert/Alert";
import SelectSearchble from "../../SelectSeachble/SelectSearchble";

export default function EspecialidadCreate({handleClose, setPing, ping, especialidadeditar, setShow}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionClinica, setSelectedOptionClinica] = useState(null);
  const [alertclini, setAlertClini] = useState(false)
  const [alertEspec, setAlertEspec] = useState(false)
  const [clinics, setClinics] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [intervalo_tiempo, setIntervaloTiempo] = useState(15)
  const [precio, setPrecio] = useState(0)
  const [icon, setIcon] = useState(null)

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    setAlertEspec(false)
  };

  const handleChangeClinica = selectedOptionClinica => {
    setSelectedOptionClinica(selectedOptionClinica);
    setAlertClini(false)
  }

  const Guardar = async (e) => {
    e.preventDefault()
    let icono = null
    if(!selectedOption){
      setAlertEspec(true)
      return
    }
    
    if(!selectedOptionClinica){
      setAlertClini(true)
      return
    }

    if(icon && icon.size > 0){
      const url = await SubirMedia(icon, null, null, GetTokenDecoded().idclinica, 'iconos_especialidades')
      icono = url
    }

    if(selectedOption && selectedOptionClinica){
      let idespecialidad = selectedOption.value
      let idclinica = selectedOptionClinica.value
      const response = await ActivarDesactivarEspecialidad({
        idclinica_especialidad: especialidadeditar.idclinica_especialidad,
        idespecialidad, idclinica, intervalo_tiempo, estado:especialidadeditar.estado, icono, precio})
      if(response.success){
        setPing(!ping)
        setSelectedOption(null)
        setSelectedOptionClinica(null)
        setIcon(null)
        setIntervaloTiempo(15)
        setPrecio(0)
        handleClose()
      }
    }
  }

  useEffect(() => {
    (async () => {
      const espec = await GetEspecialidades()
      if(espec){
        setSpecialities(espec.data.map(e => ({value: e.idespecialidad, label: e.especialidad})))
      }
      const clin = await ListarClinicas()
      if(clin){
        setClinics(clin.map(c => ({value: c.idclinica, label: c.nombre})))
      }
    })();
  }, []);

  useEffect(() => {
    if(especialidadeditar){
      setSelectedOption({value: especialidadeditar.idespecialidad, label: especialidadeditar.especialidad})
      setSelectedOptionClinica({value: especialidadeditar.idclinica, label: especialidadeditar.clinica})
      setIntervaloTiempo(especialidadeditar.intervalo_tiempo)
      setPrecio(especialidadeditar.precio)
    }else{
      setSelectedOption(null)
      setSelectedOptionClinica(null)
      setIcon(null)
      setIntervaloTiempo(15)
      setPrecio(0)
    }
  }, [especialidadeditar])
  
  return (
    <section>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <form className="needs-validation" onSubmit={Guardar}>
                            <div className="row">
                                <div className="row mb-4">
                                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Especialidad *</label>
                                    <div className="col-sm-9">
                                    <SelectSearchble
                                        listCompany={specialities} 
                                        handleChange={handleChange} 
                                        selectedOption={selectedOption} 
                                        placeholder="Seleccione especialidad"
                                        isMulti={false}
                                    />
                                    <Alert
                                        tipo_alert="danger"
                                        type={alertEspec ? 'select' : ''}
                                    />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Clinica *</label>
                                    <div className="col-sm-9">
                                    <SelectSearchble
                                        listCompany={clinics} 
                                        handleChange={handleChangeClinica} 
                                        selectedOption={selectedOptionClinica} 
                                        placeholder="Seleccione clinica"
                                        isMulti={false}
                                    />
                                    <Alert
                                        tipo_alert="danger"
                                        type={alertclini ? 'select' : ''}
                                    />
                                    </div>
                                </div>
  
                                {/* Precio de esta especialodad */}
                                <div className="row mb-4">
                                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Precio</label>
                                    <div className="col-sm-9">
                                        <input type="number" className="form-control" name="precio" value={precio}
                                        onChange={(e) => setPrecio(parseFloat(e.target.value))}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Intervalo de tiempo</label>
                                    <div className="col-sm-9">
                                        <input type="number" className="form-control" name="intervalo_tiempo" value={intervalo_tiempo}
                                        onChange={(e) => setIntervaloTiempo(parseInt(e.target.value))}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Icon</label>
                                    <div className="col-sm-9">
                                        <input type="file" className="form-control" name="icono"
                                        onChange={(e) => setIcon(e.target.files[0])}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button className="btn btn-primary w-100" type="submit">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
