import { BmHttp } from "../utils/Global";


export const ControllerVincularProcedimiento = async (nuevoProcedimiento) => {
    try {
        const response = await BmHttp().post("/cita_procedimiento", {
            idpaciente: nuevoProcedimiento.idpaciente,
            idExamenTipo: nuevoProcedimiento.idExamenTipo,
            precio: parseFloat(nuevoProcedimiento.precio),
            observaciones: nuevoProcedimiento.comentarios,
        });
    
        if (response.data.success) {
            return {
                success: true,
                message: "Procedimiento guardado correctamente",
            };
        } else {
            return {
                success: false,
                message: response.data.message || "Hubo un error al guardar el procedimiento",
            };
        }
    } catch (error) {
        console.error("Error al guardar el procedimiento:", error);
        return {
            success: false,
            message: error.response?.data?.message || "Hubo un error al guardar el procedimiento",
        };
    }
}

export const ControllerProcedimientosPrecios = async () => {
    try {
        const response = await BmHttp().get("/procedimiento_precios");
        if (response.data.success) {
            return {
                success: true,
                data: response.data.data,
            }
        }else{
            return {
                success: false,
                message: response.data.message || "Hubo un error al obtener los precios de los procedimientos",
            }
        }
    } catch (error) {
        console.error("Error fetching procedimientos:", error);
        return {
            success: false,
            message: error.response?.data?.message || "Hubo un error al obtener los precios de los procedimientos",
        };
    }
}

export const ControllerObtenerProcedimientosPendientes = async (idpaciente) => {
    try {
        const response = await BmHttp().post(`/cita_procedimiento_pendientes`,{
            idpaciente: idpaciente,
        });
        if (response.data.success) {
            return {
                success: true,
                data: response.data.data,
            };
        } else {
            return {
                success: false,
                message: response.data.message || "Hubo un error al obtener los procedimientos",
            };
        }
    } catch (error) {
        console.error("Error al obtener los procedimientos:", error);
        return {
            success: false,
            message: error.response?.data?.message || "Hubo un error al obtener los procedimientos",
        };
    }
}