/* eslint-disable array-callback-return */


import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { ListarClinicas } from '../../../Service/Serive.clinica'
import { ControllerCreateDescuento } from '../../../Service/Service.descuentos'
import Alert from '../../Alert/Alert'
import SelectSearchble from '../../SelectSeachble/SelectSearchble'

export default function CreateDescuento({setPing, ping, show, handleClose, editDescuento}) {

    const refDiv = useRef(null)
    const [send, setSend] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [msj, setMsj] = useState('')
    const [clinics, setClinics] = useState([])

    const [selectedOption, setSelectedOption] = useState(null);
    const [descuento, setDecuento] = useState({
        idclinica:[{
            value: 0,
            label: ''
        }],
        nombre:'',
        fecha_inicio:'',
        fecha_vencimiento:'',
        porcentaje_descuento:0,
        created_at: moment().format('YYYY-MM-DD'),
        update_at: moment().format('YYYY-MM-DD'),
        is_activo: true,
        clinica_descuento_relacion: [{
            idclinica: 0,
            id_descuento: 0
        }]
    })
  
  
    const handleChange = selectedOption => {
      setSelectedOption(selectedOption);
        setDecuento({
            ...descuento,
            idclinica: selectedOption.map(c => c.value)
        })
    };



    useEffect(() => {
        (async()=>{
            const clin = await ListarClinicas()
            if(clin){
              setClinics(clin.map(c => ({value: c.idclinica, label: c.nombre})))
            }
        })()
    }, [])
  
    useEffect(() => {
        (async()=>{
            if(editDescuento){
                const clin = await ListarClinicas()
                let clivalue = clin.map(c => ({value: c.idclinica, label: c.nombre}))
                let idclinicas = editDescuento.clinica_descuento_relacion.map(c => c.idclinica)
                let clinicas = clivalue.map(c =>{
                    if(idclinicas.includes(c.value)){
                        return c
                    }
                })
                setSelectedOption(clinicas)
                setDecuento({
                    idclinica: clinicas.map(c => c.value),
                    ...editDescuento
                })
            }
        })()
    }, [show])
  
  
  
    const onSubmit = async(e) => {
      e.preventDefault()
      const respon = await ControllerCreateDescuento(descuento)
      if(respon.status === 200){
        setAlertType('success')
      }else{
        setAlertType('error')
      }
      setSend(false)
      refDiv.current.scrollIntoView({behavior: 'smooth'})
      setTimeout(() => {
        setAlertType('')
        setPing(!ping)
        handleClose()
      }, 800);
    }

  return (
    <section>
    <Alert
      tipo_alert={msj || alertType}
      type={msj || alertType}
    />
    <div className="row">
        <div ref={refDiv}/>
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="nombre" className="form-label">Clinica *</label>
                                    <SelectSearchble
                                        listCompany={clinics} 
                                        handleChange={handleChange} 
                                        selectedOption={selectedOption} 
                                        placeholder="Seleccione clinicas"
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="nombre" className="form-label">Nombre *</label>
                                    <input type="text" className="form-control" id="nombre" 
                                        value={descuento.nombre}
                                        onChange={(e)=>setDecuento({...descuento, nombre: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="ruc" className="form-label">Fecha Inicio *</label>
                                    <input type="date" className="form-control" id="fecha_inicio" name="fecha_inicio" 
                                        value={descuento.fecha_inicio}
                                        onChange={(e)=>setDecuento({...descuento, fecha_inicio: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="razon_social" className="form-label">Fecha Vencimiento *</label>
                                    <input type="date" className="form-control" id="fecha_vencimiento" name="fecha_vencimiento"
                                        value={descuento.fecha_vencimiento}
                                        onChange={(e)=>setDecuento({...descuento, fecha_vencimiento: e.target.value})}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="direccion" className="form-label">Porcentaje Descuento *</label>
                                    <input type="number" className="form-control" id="porcentaje_descuento"
                                        value={descuento.porcentaje_descuento}
                                        onChange={(e)=>setDecuento({...descuento, porcentaje_descuento: parseFloat(e.target.value)})}
                                    /> 
                                </div>
                            </div>

                        </div>
                        <div className="text-center">
                            <button 
                                type="button"
                                onClick={()=>handleClose()}
                                className="btn btn-danger m-1">
                                Cancelar
                            </button>
                            <button type="submit" className="btn btn-primary">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 btnSppinAjax" 
                                style={{
                                    display: send ? 'inline-block' : 'none' 
                                }}></i>
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
