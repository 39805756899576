import React, { useEffect, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { EliminarClinica, ListarClinicas } from "../../Service/Serive.clinica";
import Alert from "../Alert/Alert";
import Speener from "../Speener/Speener";
import ClinicaCreate from "./ClinicaCreate";

export default function Clinica({tabs}) {
  const [show, setShow] = useState(false);
  const [tipoAlert, setTipoAlert] = useState('')
  const [alertMensaje, setAlertMensaje] = useState('')
  const [editClinica, setEditClinica] = useState(null)
  const [listClinica, setListClinica] = useState([])
  const [loading, setLoading] = useState(false)
  const [ping, setPing] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    (async()=>{
      setLoading(true)
      const response = await ListarClinicas()
      console.log("ListarClinida: ", response)
      if(response){
        setListClinica(response)
        setLoading(false)
      }
    })()
  }, [ping, tabs.clinicas])

  const editarClinica = (clinica) => {
    setEditClinica(clinica)
    handleShow()
  }

  const DeleteClinica = async(idclinica) => {
    const response = await EliminarClinica(idclinica)
    if(response){
      setPing(!ping)
      setTipoAlert('success')
      setAlertMensaje('Clinica eliminda con exito')
    }else{
      setTipoAlert('danger')
      setAlertMensaje('Ocurrio un error en la operacion por favor intente nuevamente')
    }
    setTimeout(()=>{
      setTipoAlert('')
      setAlertMensaje('')
    },3000)
  }

  return (
    <section>
      <Alert
        tipo_alert={tipoAlert}
        msj={alertMensaje}
      />
      <div className="row">
        <div className="col-12">

          <div className="page-title-box d-sm-flex align-items-center justify-content-between ">
            <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}><i className="bx bx-plus me-1"></i> Crear Clinica</button>
            <Speener loading={loading} />
          </div>


          <table id="tb_clinicas" className="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th>Nombre</th>
                <th>Email</th>
                <th>Representante</th>
                <th>Telefono</th>
                <th>Direccion</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                listClinica.map((clinica, index) => (
                  <tr key={clinica.idclinica}>
                    <td align="center">{index + 1}</td>
                    <td>{clinica.nombre}</td>
                    <td>{clinica.email}</td>
                    <td>{clinica.representante}</td>
                    <td>{clinica.telefono}</td>
                    <td>{clinica.direccion}</td>
                    <td align="left">
                      <div className="btn-group"><button type="button"
                        className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#;" onClick={()=>editarClinica(clinica)}>Editar</a>
                          <a className="dropdown-item" href="#;" onClick={()=>DeleteClinica(clinica.idclinica)}>Eliminar</a>
                          <div className="dropdown-divider"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Clínica
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ClinicaCreate 
            setPing={setPing}
            ping={ping}
            handleClose={handleClose}
            show={show}
            editClinica={editClinica}
          />
        </Offcanvas.Body>
      </Offcanvas>

    </section>
  );
}
