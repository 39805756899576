import React from "react";

function TableExamen(props) {
  const { examenes, type } = props;

  return (
    <table id="tb_i_examen" className="table mt-4">
      <thead className="table-light">
        <tr>
          <th>Fecha</th>
          <th>Número de orden</th>
          <th>Sala</th>
          <th># Exámenes</th>
          <th>Acción</th>
        </tr>
      </thead>
      <tbody>
        {examenes ? (
          examenes.map((examen, index) => (
            <tr key={index}>
              <td>{examen.fecha}</td>
              <td>{examen.numero_orden}</td>
              <td>{examen.sala ? examen.sala : "-"}</td>
              {type === "laboratorio" ? (
                <td>{examen.examen_cita_paciente_solicitud.length}</td>
              ) : (
                ""
              )}
              {type === "imagenes" ? (
                <td>{examen.examen_imagen_orden_solicitud.length}</td>
              ) : (
                ""
              )}
              <td>
                <div className="d-flex gap-10">
                  <button
                    className="btn btn-sm btn-primary px-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Imprimir examen"
                    onClick={() => {}}
                  >
                    <i className='bx bxs-printer' ></i>
                  </button>

                  <button
                    className="btn btn-sm btn-primary px-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Editar examen"
                    onClick={() => {}}
                  >
                    <i className='bx bxs-edit'></i>
                  </button>

                  <button
                    className="btn btn-sm btn-danger px-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Eliminar examen"
                    onClick={() => {}}
                  >
                    <i className="bx bxs-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">No hay exámenes disponibles</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default TableExamen;
